/* stylelint-disable */
/* stylelint-enable */
/* stylelint-disable */
/* stylelint-enable */
/* stylelint-disable */
/* stylelint-enable */
/* stylelint-disable */
/* stylelint-enable */
/* stylelint-disable */
/* stylelint-enable */
/* stylelint-disable */
/* stylelint-enable */
/* stylelint-disable */
/* stylelint-enable */
/* stylelint-disable */
/* stylelint-enable */
/* stylelint-disable */
/* stylelint-enable */
/* stylelint-disable */
/* stylelint-enable */
/* stylelint-disable */
/* stylelint-enable */
/* stylelint-disable */
/* stylelint-enable */
/* stylelint-disable */
/* stylelint-enable */
/* stylelint-disable */
/* stylelint-enable */
/* stylelint-disable */
/* stylelint-enable */
/* stylelint-disable */
/* stylelint-enable */
/* stylelint-disable */
/* stylelint-enable */
/* stylelint-disable */
/* stylelint-enable */
/* stylelint-disable */
/* stylelint-enable */
/* stylelint-disable */
/* stylelint-enable */
/* stylelint-disable */
/* stylelint-enable */
/* stylelint-disable */
/* stylelint-enable */
/* stylelint-disable */
/* stylelint-enable */
/* stylelint-disable */
/* stylelint-enable */
/* stylelint-disable */
/* stylelint-enable */
/* stylelint-disable */
/* stylelint-enable */
.profile-tab {
  background-color: #f9fafb;
  display: flex;
  flex-direction: row;
  margin: 0 auto;
  min-height: 75vh;
  z-index: 300; }
  .mobile .profile-tab {
    margin-bottom: 50px; }
    @media (min-width: 30rem) {
      .mobile .profile-tab {
        margin-bottom: 100px; } }
    @media (min-width: 73rem) {
      .mobile .profile-tab {
        margin-bottom: 0; } }

.tab-side-column {
  flex-direction: column;
  display: none;
  margin-left: 1.875rem;
  flex-basis: 21.25rem;
  width: 21.25rem;
  min-width: 21.25rem; }
  @media (min-width: 74.375rem) {
    .tab-side-column {
      display: flex; } }

.tab-panel {
  width: 100%;
  margin-bottom: 1.25rem;
  margin: 0 auto;
  max-width: 1200px;
  padding-top: 1.5rem; }
