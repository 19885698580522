/* stylelint-disable */
/* stylelint-enable */
/* stylelint-disable */
/* stylelint-enable */
/* stylelint-disable */
/* stylelint-enable */
.styles__team-history-card___yNoU8 {
  flex: 1 1 auto;
  padding-bottom: 1.5rem; }

.styles__team-history-card__header___R_Pty {
  padding-bottom: 1rem; }

.styles__team-history-card__list___3r6Jq {
  display: flex;
  flex-direction: column; }

.styles__team-history-card__team___1YAW4 {
  display: flex; }
  .styles__team-history-card__team___1YAW4:not(:last-child) {
    margin-bottom: 1rem; }

.styles__team-history-card__team-info___N83XJ {
  flex: 2 2 auto;
  max-width: 11.8125rem; }

.styles__team-history-card__organization___3ggrA {
  display: block; }

.styles__team-history-card__team-name___3vXPz {
  margin-top: 0.25rem;
  display: block; }

.styles__team-history-card__positions___27F1F {
  flex: 1 1 auto;
  margin-left: 0.5rem; }

.styles__team-history-card__jersey___1xzF6 {
  flex: 1 1 auto;
  margin-right: 1.25rem;
  margin-left: 0.5rem;
  max-width: 2.9375rem; }
