/* stylelint-disable */
/* stylelint-enable */
/* stylelint-disable */
/* stylelint-enable */
/* stylelint-disable */
/* stylelint-enable */
/* stylelint-disable */
/* stylelint-enable */
/* stylelint-disable */
/* stylelint-enable */
/* stylelint-disable */
/* stylelint-enable */
/* stylelint-disable */
/* stylelint-enable */
/* stylelint-disable */
/* stylelint-enable */
/* stylelint-disable */
/* stylelint-enable */
/* stylelint-disable */
/* stylelint-enable */
/* stylelint-disable */
/* stylelint-enable */
/* stylelint-disable */
/* stylelint-enable */
/* stylelint-disable */
/* stylelint-enable */
/* stylelint-disable */
/* stylelint-enable */
/* stylelint-disable */
/* stylelint-enable */
/* stylelint-disable */
/* stylelint-enable */
/* stylelint-disable */
/* stylelint-enable */
/* stylelint-disable */
/* stylelint-enable */
/* stylelint-disable */
/* stylelint-enable */
/* stylelint-disable */
/* stylelint-enable */
/* stylelint-disable */
/* stylelint-enable */
/* stylelint-disable */
/* stylelint-enable */
/* stylelint-disable */
/* stylelint-enable */
/* stylelint-disable */
/* stylelint-enable */
/* stylelint-disable */
/* stylelint-enable */
/* stylelint-disable */
/* stylelint-enable */
.image-upload-content .image-upload-area.drag-over .image-upload-instructions {
  border: 2px solid rgba(167, 174, 181, 0.6); }

.image-upload-content .image-upload-area.drag-over .drop-instructions {
  display: block !important; }

.image-upload-content .image-upload-area.drag-over .drag-instructions, .image-upload-content .image-upload-area.drag-over .or-instruction, .image-upload-content .image-upload-area.drag-over .upload-an-image {
  display: none !important; }

.image-upload-content .image-upload-area .upload-an-image {
  display: inline; }

.image-upload-content .image-upload-area .image-upload-instructions {
  padding: 2rem 1rem;
  margin: 1rem 0 2rem;
  background: #f9fafb;
  border-radius: 8px;
  border: 2px dashed rgba(167, 174, 181, 0.6); }

.image-upload-content .image-upload-area .upload-area .drop-instructions {
  display: none; }

.image-upload-content .image-upload-area .upload-area:not(.in-progress) .image-upload-instructions {
  text-align: center; }

.image-upload-content .image-upload-area .upload-area:not(.in-progress) .image-upload-in-progress {
  display: none; }

.image-upload-content .image-upload-area .upload-area.in-progress .image-upload-in-progress {
  display: flex;
  justify-content: center; }

.image-upload-content .image-upload-area .upload-area.in-progress .image-upload-instructions, .image-upload-content .image-upload-area .upload-area.in-progress .image-upload-requirements-text {
  display: none; }
