/* stylelint-disable */
/* stylelint-enable */
/* stylelint-disable */
/* stylelint-enable */
/* stylelint-disable */
/* stylelint-enable */
/* stylelint-disable */
/* stylelint-enable */
/* stylelint-disable */
/* stylelint-enable */
/* stylelint-disable */
/* stylelint-enable */
/* stylelint-disable */
/* stylelint-enable */
/* stylelint-disable */
/* stylelint-enable */
/* stylelint-disable */
/* stylelint-enable */
/* stylelint-disable */
/* stylelint-enable */
/* stylelint-disable */
/* stylelint-enable */
/* stylelint-disable */
/* stylelint-enable */
/* stylelint-disable */
/* stylelint-enable */
/* stylelint-disable */
/* stylelint-enable */
/* stylelint-disable */
/* stylelint-enable */
/* stylelint-disable */
/* stylelint-enable */
/* stylelint-disable */
/* stylelint-enable */
/* stylelint-disable */
/* stylelint-enable */
/* stylelint-disable */
/* stylelint-enable */
/* stylelint-disable */
/* stylelint-enable */
/* stylelint-disable */
/* stylelint-enable */
/* stylelint-disable */
/* stylelint-enable */
/* stylelint-disable */
/* stylelint-enable */
/* stylelint-disable */
/* stylelint-enable */
/* stylelint-disable */
/* stylelint-enable */
/* stylelint-disable */
/* stylelint-enable */
.styles__card-stat___TQtHp {
  flex: 1 1 auto;
  padding: 0 1rem 1rem 0; }
  @media (min-width: 30rem) {
    .styles__card-stat___TQtHp {
      flex: 1 1 100%; } }

.styles__card-stat__content___l5ZrV {
  display: flex;
  position: relative;
  margin: 0 -1rem -0.5rem;
  padding: 0 1rem 0.5rem; }

.styles__card-stat__verified___pE0RY {
  margin-left: 0.5rem; }
  .styles__card-stat__verified___pE0RY svg {
    width: 1.8rem;
    height: 0.625rem; }
    .styles__card-stat__verified___pE0RY svg, .styles__card-stat__verified___pE0RY svg path, .styles__card-stat__verified___pE0RY svg polygon {
      fill: #72C91A; }

.styles__card-stat__label___25N4T {
  margin-bottom: 0.5rem; }

.styles__card-stat__value___2Qr8R {
  font-family: helvetica, arial, sans-serif;
  font-size: 1rem;
  font-weight: 400;
  color: #4e5d6c; }
