/* stylelint-disable */
/* stylelint-enable */
/* stylelint-disable */
/* stylelint-enable */
/* stylelint-disable */
/* stylelint-enable */
/* stylelint-disable */
/* stylelint-enable */
/* stylelint-disable */
/* stylelint-enable */
/* stylelint-disable */
/* stylelint-enable */
/* stylelint-disable */
/* stylelint-enable */
/* stylelint-disable */
/* stylelint-enable */
/* stylelint-disable */
/* stylelint-enable */
/* stylelint-disable */
/* stylelint-enable */
/* stylelint-disable */
/* stylelint-enable */
/* stylelint-disable */
/* stylelint-enable */
/* stylelint-disable */
/* stylelint-enable */
/* stylelint-disable */
/* stylelint-enable */
/* stylelint-disable */
/* stylelint-enable */
/* stylelint-disable */
/* stylelint-enable */
/* stylelint-disable */
/* stylelint-enable */
/* stylelint-disable */
/* stylelint-enable */
/* stylelint-disable */
/* stylelint-enable */
/* stylelint-disable */
/* stylelint-enable */
/* stylelint-disable */
/* stylelint-enable */
/* stylelint-disable */
/* stylelint-enable */
/* stylelint-disable */
/* stylelint-enable */
/* stylelint-disable */
/* stylelint-enable */
/* stylelint-disable */
/* stylelint-enable */
/* stylelint-disable */
/* stylelint-enable */
.profile-notification {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  z-index: 300;
  padding: 1rem 2.5rem 1rem 1rem;
  background-color: #232a31;
  color: white;
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.4); }
  .profile-notification.success {
    background-color: #78a100; }
  .profile-notification.error {
    background-color: #e81c00; }
  .profile-notification > svg, .profile-notification > svg path {
    fill: white; }
  @media (min-width: 30rem) {
    .profile-notification {
      bottom: 2.5rem;
      left: auto;
      right: 2.5rem;
      width: auto;
      border-radius: 0.125rem; } }
  .profile-notification a {
    text-decoration: underline;
    cursor: pointer; }
    .profile-notification a:hover {
      color: #e6e6e6; }
  .profile-notification svg {
    position: absolute;
    right: 1rem;
    bottom: 1rem;
    width: 1rem;
    height: 1rem;
    cursor: pointer; }
    .profile-notification svg, .profile-notification svg path {
      fill: white; }
      .profile-notification svg:hover, .profile-notification svg path:hover {
        fill: #e6e6e6; }
