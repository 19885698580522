/*doc
---
title: Variables
name: 01-variables
category: Elements
---

### Colors

#### Dark UI Colors

<div class="ko-color-blocks">
    <div class="ko-color-block-ui_dark_color">
        <div>$dui_dark_color</div>
        <div>#232A31</div>
    </div>
    <div class="ko-color-block-ui_dark_medium_color">
        <div>$ui_dark_medium_color</div>
        <div>#38434F</div>
    </div>
    <div class="ko-color-block-ui_dark_light_color">
        <div>$ui_dark_light_color</div>
        <div>#4E5D6C</div>
    </div>
</div>

#### Gray UI Colors

<div class="ko-color-blocks">
    <div class="ko-color-block-ui_gray_color">
        <div>$ui_gray_color</div>
        <div>#8A8A8A</div>
    </div>
    <div class="ko-color-block-ui_gray_medium_color">
        <div>$ui_gray_medium_color</div>
        <div>#E8E8E8</div>
    </div>
    <div class="ko-color-block-ui_gray_light_color">
        <div>$ui_gray_light_color</div>
        <div>#F5F5F5</div>
    </div>
</div>

#### Accent UI Colors

<div class="ko-color-blocks">
    <div class="ko-color-block-ui_blue_color">
        <div>$ui_blue_color</div>
        <div>#00AEEF</div>
    </div>
    <div class="ko-color-block-ui_orange_color">
        <div>$ui_orange_color</div>
        <div>#FF6600</div>
    </div>
</div>

#### Alert UI Colors

<div class="ko-color-blocks">
    <div class="ko-color-block-ui_red_color">
        <div>$ui_red_color</div>
        <div>#D81D1D</div>
    </div>
    <div class="ko-color-block-ui_yellow_color">
        <div>$ui_yellow_color</div>
        <div>#FABE39</div>
    </div>
    <div class="ko-color-block-ui_green_color">
        <div>$ui_green_color</div>
        <div>#6AA220</div>
    </div>
</div>

#### Grayscale UI Text Colors

<div class="ko-color-blocks">
    <div class="ko-color-block-ui_text_dark_color">
        <div>$ui_text_dark_color</div>
        <div>#333333</div>
    </div>
    <div class="ko-color-block-ui_text_medium_color">
        <div>$ui_text_medium_color</div>
        <div>#666666</div>
    </div>
    <div class="ko-color-block-ui_text_light_color">
        <div>$ui_text_light_color</div>
        <div>#999999</div>
    </div>
</div>
<div class="ko-color-blocks">
    <div class="ko-color-block-ui_text_extra_light_color">
        <div>$ui_text_extra_light_color</div>
        <div>#CCCCCC</div>
    </div>
</div>

#### Base UI Colors

<div class="ko-color-blocks">
    <div class="ko-color-block-ui_black_color">
        <div>$ui_black_color</div>
        <div>#FFFFFF</div>
    </div>
    <div class="ko-color-block-ui_white_color">
        <div>$ui_white_color</div>
        <div>#000000</div>
    </div>
</div>

#### Team Colors

<div class="ko-color-blocks">
    <div class="ko-color-block-tagging_team_white_color">
        <div>$tagging_team_white_color</div>
        <div>#E9E9E9</div>
    </div>
    <div class="ko-color-block-tagging_team_gray_color">
        <div>$tagging_team_gray_color</div>
        <div>#8C9196</div>
    </div>
    <div class="ko-color-block-tagging_team_black_color">
        <div>$tagging_team_black_color</div>
        <div>#404040</div>
    </div>
</div>

<div class="ko-color-blocks">
    <div class="ko-color-block-tagging_team_yellow_color">
        <div>$tagging_team_yellow_color</div>
        <div>#FFDD30</div>
    </div>
    <div class="ko-color-block-tagging_team_orange_color">
        <div>$tagging_team_orange_color</div>
        <div>#ED9827</div>
    </div>
    <div class="ko-color-block-tagging_team_red_color">
        <div>$tagging_team_red_color</div>
        <div>#DF4953</div>
    </div>
</div>

<div class="ko-color-blocks">
    <div class="ko-color-block-tagging_team_green_color">
        <div>$tagging_team_green_color</div>
        <div>#42BE39</div>
    </div>
    <div class="ko-color-block-tagging_team_blue_color">
        <div>$tagging_team_blue_color</div>
        <div>#3985BE</div>
    </div>
    <div class="ko-color-block-tagging_team_purple_color">
        <div>$tagging_team_purple_color</div>
        <div>#9F62B1</div>
    </div>
</div>

### Font Sizes

These should really be used by the `font-size()` mixin to maintain consistent typography. The possible values are (from smallest to largest): **small**, **p**, and **5** to **1** (or **h5** to **h1** if that's your jam).

### Z-index

Try to stick to the variables defined here for z-index values, e.g., `z-index: $z-1` instead of arbitrary numbers. The possible values are: **z-1** (100) to **z-7** (700).

### Transition Lengths

Again, try to stick to these instead of arbitrary values. The possible values are: **t-quick** (0.2s), **t-med** (0.3s), and **t-slow** (0.5s).

### Common Breakpoints

Put breakpoints that you end up referencing a lot here and give it a prefix of `b-`, e.g., `b-showdesktop`. These are usually referenced by the `respond()` mixin.

*/
.slider {
  display: flex;
  align-items: center;
  height: 2.25rem;
  border-radius: 8px;
  background-color: #38434f; }
  .slider .minus, .slider .plus {
    font-family: monospace;
    font-size: 2.25rem;
    font-weight: bold;
    color: #FFFFFF;
    text-align: center;
    margin: 0 0.5rem;
    user-select: none; }
    .slider .minus:hover, .slider .plus:hover {
      cursor: pointer; }
  .slider .drag-area {
    height: 1rem;
    flex: 1 1 auto;
    position: relative;
    display: flex;
    align-items: center; }
    .slider .drag-area .bar {
      width: 100%;
      height: 0.125rem;
      background-color: #FFFFFF; }
    .slider .drag-area .marker {
      background-color: #FFFFFF;
      width: 1rem;
      height: 1rem;
      border-radius: 50%;
      position: absolute;
      left: 0%;
      top: 0;
      bottom: 0; }
