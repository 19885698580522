/* stylelint-disable */
/* stylelint-enable */
/* stylelint-disable */
/* stylelint-enable */
/* stylelint-disable */
/* stylelint-enable */
/* stylelint-disable */
/* stylelint-enable */
/* stylelint-disable */
/* stylelint-enable */
/* stylelint-disable */
/* stylelint-enable */
/* stylelint-disable */
/* stylelint-enable */
/* stylelint-disable */
/* stylelint-enable */
/* stylelint-disable */
/* stylelint-enable */
/* stylelint-disable */
/* stylelint-enable */
/* stylelint-disable */
/* stylelint-enable */
/* stylelint-disable */
/* stylelint-enable */
/* stylelint-disable */
/* stylelint-enable */
/* stylelint-disable */
/* stylelint-enable */
/* stylelint-disable */
/* stylelint-enable */
/* stylelint-disable */
/* stylelint-enable */
/* stylelint-disable */
/* stylelint-enable */
/* stylelint-disable */
/* stylelint-enable */
/* stylelint-disable */
/* stylelint-enable */
/* stylelint-disable */
/* stylelint-enable */
/* stylelint-disable */
/* stylelint-enable */
/* stylelint-disable */
/* stylelint-enable */
/* stylelint-disable */
/* stylelint-enable */
/* stylelint-disable */
/* stylelint-enable */
/* stylelint-disable */
/* stylelint-enable */
/* stylelint-disable */
/* stylelint-enable */
/*doc
---
title: Variables
name: 01-variables
category: Elements
---

### Colors

#### Dark UI Colors

<div class="ko-color-blocks">
    <div class="ko-color-block-ui_dark_color">
        <div>$dui_dark_color</div>
        <div>#232A31</div>
    </div>
    <div class="ko-color-block-ui_dark_medium_color">
        <div>$ui_dark_medium_color</div>
        <div>#38434F</div>
    </div>
    <div class="ko-color-block-ui_dark_light_color">
        <div>$ui_dark_light_color</div>
        <div>#4E5D6C</div>
    </div>
</div>

#### Gray UI Colors

<div class="ko-color-blocks">
    <div class="ko-color-block-ui_gray_color">
        <div>$ui_gray_color</div>
        <div>#8A8A8A</div>
    </div>
    <div class="ko-color-block-ui_gray_medium_color">
        <div>$ui_gray_medium_color</div>
        <div>#E8E8E8</div>
    </div>
    <div class="ko-color-block-ui_gray_light_color">
        <div>$ui_gray_light_color</div>
        <div>#F5F5F5</div>
    </div>
</div>

#### Accent UI Colors

<div class="ko-color-blocks">
    <div class="ko-color-block-ui_blue_color">
        <div>$ui_blue_color</div>
        <div>#00AEEF</div>
    </div>
    <div class="ko-color-block-ui_orange_color">
        <div>$ui_orange_color</div>
        <div>#FF6600</div>
    </div>
</div>

#### Alert UI Colors

<div class="ko-color-blocks">
    <div class="ko-color-block-ui_red_color">
        <div>$ui_red_color</div>
        <div>#D81D1D</div>
    </div>
    <div class="ko-color-block-ui_yellow_color">
        <div>$ui_yellow_color</div>
        <div>#FABE39</div>
    </div>
    <div class="ko-color-block-ui_green_color">
        <div>$ui_green_color</div>
        <div>#6AA220</div>
    </div>
</div>

#### Grayscale UI Text Colors

<div class="ko-color-blocks">
    <div class="ko-color-block-ui_text_dark_color">
        <div>$ui_text_dark_color</div>
        <div>#333333</div>
    </div>
    <div class="ko-color-block-ui_text_medium_color">
        <div>$ui_text_medium_color</div>
        <div>#666666</div>
    </div>
    <div class="ko-color-block-ui_text_light_color">
        <div>$ui_text_light_color</div>
        <div>#999999</div>
    </div>
</div>
<div class="ko-color-blocks">
    <div class="ko-color-block-ui_text_extra_light_color">
        <div>$ui_text_extra_light_color</div>
        <div>#CCCCCC</div>
    </div>
</div>

#### Base UI Colors

<div class="ko-color-blocks">
    <div class="ko-color-block-ui_black_color">
        <div>$ui_black_color</div>
        <div>#FFFFFF</div>
    </div>
    <div class="ko-color-block-ui_white_color">
        <div>$ui_white_color</div>
        <div>#000000</div>
    </div>
</div>

#### Team Colors

<div class="ko-color-blocks">
    <div class="ko-color-block-tagging_team_white_color">
        <div>$tagging_team_white_color</div>
        <div>#E9E9E9</div>
    </div>
    <div class="ko-color-block-tagging_team_gray_color">
        <div>$tagging_team_gray_color</div>
        <div>#8C9196</div>
    </div>
    <div class="ko-color-block-tagging_team_black_color">
        <div>$tagging_team_black_color</div>
        <div>#404040</div>
    </div>
</div>

<div class="ko-color-blocks">
    <div class="ko-color-block-tagging_team_yellow_color">
        <div>$tagging_team_yellow_color</div>
        <div>#FFDD30</div>
    </div>
    <div class="ko-color-block-tagging_team_orange_color">
        <div>$tagging_team_orange_color</div>
        <div>#ED9827</div>
    </div>
    <div class="ko-color-block-tagging_team_red_color">
        <div>$tagging_team_red_color</div>
        <div>#DF4953</div>
    </div>
</div>

<div class="ko-color-blocks">
    <div class="ko-color-block-tagging_team_green_color">
        <div>$tagging_team_green_color</div>
        <div>#42BE39</div>
    </div>
    <div class="ko-color-block-tagging_team_blue_color">
        <div>$tagging_team_blue_color</div>
        <div>#3985BE</div>
    </div>
    <div class="ko-color-block-tagging_team_purple_color">
        <div>$tagging_team_purple_color</div>
        <div>#9F62B1</div>
    </div>
</div>

### Font Sizes

These should really be used by the `font-size()` mixin to maintain consistent typography. The possible values are (from smallest to largest): **small**, **p**, and **5** to **1** (or **h5** to **h1** if that's your jam).

### Z-index

Try to stick to the variables defined here for z-index values, e.g., `z-index: $z-1` instead of arbitrary numbers. The possible values are: **z-1** (100) to **z-7** (700).

### Transition Lengths

Again, try to stick to these instead of arbitrary values. The possible values are: **t-quick** (0.2s), **t-med** (0.3s), and **t-slow** (0.5s).

### Common Breakpoints

Put breakpoints that you end up referencing a lot here and give it a prefix of `b-`, e.g., `b-showdesktop`. These are usually referenced by the `respond()` mixin.

*/
/*doc
---
title: Mixins
name: 02-mixins
category: Elements
---

### Bourbon & Neat

First off, be aware that we're using [Bourbon](http://bourbon.io/docs/) and [Neat](http://neat.bourbon.io/docs/) in Kickoff. Read up on their documentation to familiarize yourself with the mixins we get with those libraries.

### Respond()

This is our main responsive design mixin. It's built in mobile-first fashion and uses `em`s as the unit, and here's the usage:


```
.widget {

    // Default mobile-first styles here

    @include respond(20) {
        // Styles for screens from 20ems on up
    }

    @include respond(55) {
        // Styles for screens from 55rems on up
    }

    @include respond($b-showdesktop) {
        // Styles for screens from this variable on up
    }

}
```

There's an optional `ie` argument that you can pass in if you also want these styles to apply to IE8 since that browser doesn't like media queries.


```
.widget {

    // Default mobile-first styles here

    @include respond(20, ie) {
        // Styles for screens from 20ems on up and IE8
    }

}
```

Finally, the mixin applies ALL the styles to any page with a class of `desktop-only` on the `<body>`. This is to support older non-responsive and non-mobile-first pages.

### Font sizes

We've got a mixin for maintaining consistent font-sizes on a nice typographic scale. Any time you need to change the font size of an element, please use this mixin.
The possible values are (from smallest to largest): **small**, **p**, **5**, **4**, **3**, **2**, and **1**.

```
.widget {
    @include font-size(small) // .75rem
}

.widget {
    @include font-size(p) // default 1rem font size
}

.widget {
    @include font-size(5) // h5 size: 1.125rem
}

.widget {
    @include font-size(4) // h4 size: 1.3125rem
}

// etc

```

### Trade-heading()

A quick mixin for giving you that bold condensed trade heading that we all love. Pass in 'caps' to uppercase it, e.g., `trade-heading(caps)`

### Text-overflow()

For when you just don't have enough room. Hides overflow, doesn't wrap white-space and cuts off text with an ellipsis.

### CSS Spinner

There's a default `css-spinner` class that you can throw on any `<div>` but you'll likely want to customize it a bit.

```html_example
<div class="css-spinner"></div>
```

Here's how the mixin works if you want to put it on your own element and customize the colors.

```
.widget {

    // Arguments:
    // 1. border-width
    // 2. main color
    // 3. background color
    // 4. total width/height
    // 5. margin (optional. defaults to 0.)

    @include css-spinner(.5rem, $orange, $medium-gray, 4rem, 1rem);

}
```

*/
@-webkit-keyframes spinner-rotation {
  from {
    -webkit-transform: rotate(0deg); }
  to {
    -webkit-transform: rotate(359deg); } }

@-moz-keyframes spinner-rotation {
  from {
    -moz-transform: rotate(0deg); }
  to {
    -moz-transform: rotate(359deg); } }

@keyframes spinner-rotation {
  from {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg); }
  to {
    -webkit-transform: rotate(359deg);
    -moz-transform: rotate(359deg);
    -ms-transform: rotate(359deg);
    -o-transform: rotate(359deg);
    transform: rotate(359deg); } }

.css-spinner {
  -webkit-animation: spinner-rotation 0.75s infinite linear;
  -moz-animation: spinner-rotation 0.75s infinite linear;
  animation: spinner-rotation 0.75s infinite linear;
  clear: both;
  height: 4rem;
  width: 4rem;
  margin: 1rem auto 1rem auto;
  position: relative;
  border-top: 0.5rem solid #FF6600;
  border-left: 0.5rem solid #E8E8E8;
  border-bottom: 0.5rem solid #E8E8E8;
  border-right: 0.5rem solid #E8E8E8;
  border-radius: 100%;
  display: block; }
  .no-cssanimations .css-spinner {
    display: none; }

.css-spinner-message {
  margin-bottom: 1.5rem;
  padding: 0 1.5rem;
  text-align: center;
  font-size: 1rem;
  font-style: italic;
  color: #8A8A8A; }
  .no-cssanimations .css-spinner-message {
    font-weight: bold;
    padding: 1.5rem;
    color: #FF6600;
    font-size: 20px; }

.timeline-tab {
  display: flex;
  justify-content: center;
  margin-bottom: 1.5rem;
  margin: 0 auto;
  max-width: 1200px;
  padding-top: 1rem;
  width: 100%; }
  .timeline-tab h3 {
    color: rgba(19, 41, 63, 0.8);
    font-size: 14px;
    font-weight: bold;
    margin: 0 0 1rem 0; }
  .timeline-tab .left-column {
    min-width: 0; }
  .timeline-tab .bio {
    word-break: break-word;
    word-wrap: break-word; }
  .timeline-tab .profile-feed {
    flex: 2 0 200px;
    margin: 0 20px;
    transform: translate3d(0, 0, 0);
    -webkit-backface-visibility: hidden;
    -webkit-overflow-scrolling: touch; }
    @media only screen and (max-device-width: 740px) and (orientation: portrait) {
      .timeline-tab .profile-feed {
        margin-bottom: 60px; } }
    .timeline-tab .profile-feed .feeditem {
      margin-left: auto;
      margin-right: auto; }
  .timeline-tab #feed {
    margin: 0 auto;
    max-width: 496px; }
  .timeline-tab .section-divider {
    border-top: 1px solid #e4e7eb;
    height: 0;
    margin: 1rem 0; }
  @media (min-width: 68.75rem) {
    .timeline-tab {
      padding: 1rem 1.5rem; } }

.left-column,
.right-column {
  display: none;
  flex: 1 2 180px;
  max-width: 300px; }
  @media (min-width: 68.75rem) {
    .left-column,
    .right-column {
      display: block; } }

@media (min-width: 68.75rem) {
  .right-column {
    position: sticky;
    top: 4.125rem;
    align-self: flex-start;
    animation: 1ms linear 0s infinite forceSafariRepaint; } }

.more-stats, .more-teams {
  cursor: pointer;
  color: rgba(19, 41, 63, 0.4);
  font-weight: bold;
  float: right;
  font-size: smaller; }
  .more-stats:hover, .more-teams:hover {
    color: rgba(19, 41, 63, 0.8); }

.stats-list li:not(:last-child) {
  margin-bottom: 0.25rem; }

.stats-list li .uni-text {
  display: inline; }

.stats-list .nike-verified {
  margin-left: 0.5rem; }
  .stats-list .nike-verified svg {
    width: 1.8rem;
    height: 0.625rem; }
    .stats-list .nike-verified svg, .stats-list .nike-verified svg path, .stats-list .nike-verified svg polygon {
      fill: #72C91A; }

.stats-more-container {
  display: flex;
  justify-content: flex-end;
  margin-top: -1.75rem; }

.col-team-list .team-logo {
  align-items: center;
  background-size: cover;
  border-radius: 50%;
  color: #e6f2ff;
  font-size: 18px;
  font-weight: bold;
  height: 60px;
  margin-right: 5px;
  width: 60px; }
  .col-team-list .team-logo .longname {
    font-size: 15px; }

.col-team-list .team-container {
  display: -webkit-box;
  display: -moz-box;
  display: box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex; }

.col-team-list li {
  margin-bottom: 1rem; }

.col-team-list .selectable:hover h4 {
  transition: color 0.25s ease;
  color: rgba(19, 41, 63, 0.8);
  text-decoration: underline;
  text-decoration-color: rgba(19, 41, 63, 0.4); }

.col-team-list .team-logo,
.col-team-list .team-text {
  display: flex;
  flex-direction: column;
  justify-content: center; }

.col-team-list .team-text {
  flex: 1;
  padding-right: 0.5rem; }
  .col-team-list .team-text a {
    margin-left: auto;
    min-width: 25%; }
    .col-team-list .team-text a:hover {
      -webkit-text-decoration: underline;
      text-decoration: underline; }
  .col-team-list .team-text .game-day {
    display: -webkit-box;
    display: -moz-box;
    display: box;
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flexbox;
    display: flex;
    align-items: center;
    justify-content: space-between; }
  .col-team-list .team-text .score {
    padding: 0 0.25rem;
    border-left: 1px solid #e4e7eb;
    border-radius: 4px;
    min-height: 3rem;
    min-width: 3rem;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    white-space: nowrap; }
    .col-team-list .team-text .score strong {
      font-weight: 700; }

.team-history li:hover h4 {
  transition: color 0.25s ease;
  color: rgba(19, 41, 63, 0.8);
  text-decoration: underline;
  text-decoration-color: rgba(19, 41, 63, 0.4); }

.timeline-intro-close {
  position: absolute;
  top: 0;
  right: 0;
  cursor: pointer; }
  .timeline-intro-close svg {
    width: 1rem;
    height: 1rem;
    fill: rgba(19, 41, 63, 0.8); }
    .timeline-intro-close svg:hover {
      fill: rgba(19, 41, 63, 0.65); }

.feeditem {
  margin-bottom: 12px;
  background-clip: padding-box; }
  @media (min-width: 48rem) {
    .feeditem {
      margin-bottom: 14px; } }
  .feeditem .content a {
    box-sizing: content-box; }
  .feeditem .feedcontent_w::after {
    clear: both;
    content: "";
    display: table; }
  .feeditem .feedattachments {
    margin: 1rem 0 0;
    position: relative; }
    .feeditem .feedattachments > a > span {
      text-align: center;
      -webkit-font-smoothing: antialiased; }
    .feeditem .feedattachments .feedattachments-wrapper {
      margin-top: 14px; }
      .feeditem .feedattachments .feedattachments-wrapper::after {
        clear: both;
        content: "";
        display: table; }
  .feeditem .img.med {
    width: 154px; }

.embeddable-video {
  display: block;
  width: 100%;
  min-height: 100% !important; }

.content {
  position: relative;
  -webkit-font-smoothing: antialiased; }
  .content::after {
    clear: both;
    content: "";
    display: table; }
  .content .content-item {
    border-bottom: 1px solid #e8e8e8;
    margin-bottom: 14px;
    padding-bottom: 11px; }
    .content .content-item:last-child {
      border-bottom: none;
      padding-bottom: 0;
      margin-bottom: 0; }
  .content .image-border:after {
    border: 1px solid rgba(0, 0, 0, 0.1);
    bottom: 0;
    content: '';
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    height: inherit;
    width: inherit;
    box-sizing: border-box; }
    .content .image-border:after::after {
      clear: both;
      content: "";
      display: table; }
  .content .fake-timeline-media {
    margin: 0;
    display: block;
    width: 100%;
    position: relative;
    border: 0;
    z-index: 1;
    background-color: black;
    padding-top: 56.25%;
    cursor: pointer; }
    .content .fake-timeline-media:hover img.video-thumbnail {
      z-index: 2; }
    .content .fake-timeline-media:hover .play-video {
      background-color: rgba(35, 42, 49, 0.7); }
    .content .fake-timeline-media.small {
      display: none; }
    .content .fake-timeline-media img, .content .fake-timeline-media iframe {
      display: block;
      width: 100%;
      height: 100%;
      max-width: 100%;
      max-height: 100%;
      margin: auto; }

.fake-timeline-name {
  font-weight: bold;
  color: #13293f;
  opacity: 1;
  -webkit-font-smoothing: antialiased; }

.feeditem-header {
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-content: flex-start; }
  .feeditem--loading .feeditem-header {
    padding: 12px 12px 0; }
    @media (min-width: 48rem) {
      .feeditem--loading .feeditem-header {
        padding: 14px 14px 0; } }
  .no-flexbox .feeditem-header {
    position: relative; }
    .no-flexbox .feeditem-header::after {
      clear: both;
      content: "";
      display: table; }
  .feeditem-header .feedcontent {
    flex-grow: 1;
    flex-shrink: 1;
    position: relative; }
    @media (min-width: 48rem) {
      .feeditem-header .feedcontent {
        padding-top: 3px; } }
    .no-flexbox .feeditem-header .feedcontent {
      width: calc(100% - 64px);
      float: left;
      display: inline-block; }
  .feeditem-header .profile {
    flex-shrink: 0;
    border: 0;
    border-radius: 50%;
    margin-right: 6px;
    padding: 0;
    background-clip: padding-box;
    display: block; }
    @media (min-width: 48rem) {
      .feeditem-header .profile {
        width: 44px;
        height: 44px;
        margin-right: 8px; } }
    .no-flexbox .feeditem-header .profile {
      float: left;
      display: inline-block; }
    .feeditem-header .profile img {
      height: 33px;
      width: 33px;
      border-radius: 50%; }
      @media (min-width: 48rem) {
        .feeditem-header .profile img {
          height: 44px;
          width: 44px; } }
  .feeditem-header .feedmessage {
    font-size: 14px;
    margin-bottom: 3px;
    line-height: 1.1rem;
    color: #13293f;
    -webkit-font-smoothing: subpixel-antialiased; }
    @media (min-width: 48rem) {
      .feeditem-header .feedmessage {
        font-size: 15px;
        margin-bottom: 5px;
        line-height: 1.2rem; } }
    .feeditem-header .feedmessage .action {
      color: rgba(19, 41, 63, 0.65); }

.intro-feedmessage {
  display: inline-flex; }

@keyframes forceSafariRepaint {
  0% {
    opacity: .99; }
  50% {
    opacity: 1; }
  100% {
    opacity: .99; } }
