/*doc
---
title: Variables
name: 01-variables
category: Elements
---

### Colors

#### Dark UI Colors

<div class="ko-color-blocks">
    <div class="ko-color-block-ui_dark_color">
        <div>$dui_dark_color</div>
        <div>#232A31</div>
    </div>
    <div class="ko-color-block-ui_dark_medium_color">
        <div>$ui_dark_medium_color</div>
        <div>#38434F</div>
    </div>
    <div class="ko-color-block-ui_dark_light_color">
        <div>$ui_dark_light_color</div>
        <div>#4E5D6C</div>
    </div>
</div>

#### Gray UI Colors

<div class="ko-color-blocks">
    <div class="ko-color-block-ui_gray_color">
        <div>$ui_gray_color</div>
        <div>#8A8A8A</div>
    </div>
    <div class="ko-color-block-ui_gray_medium_color">
        <div>$ui_gray_medium_color</div>
        <div>#E8E8E8</div>
    </div>
    <div class="ko-color-block-ui_gray_light_color">
        <div>$ui_gray_light_color</div>
        <div>#F5F5F5</div>
    </div>
</div>

#### Accent UI Colors

<div class="ko-color-blocks">
    <div class="ko-color-block-ui_blue_color">
        <div>$ui_blue_color</div>
        <div>#00AEEF</div>
    </div>
    <div class="ko-color-block-ui_orange_color">
        <div>$ui_orange_color</div>
        <div>#FF6600</div>
    </div>
</div>

#### Alert UI Colors

<div class="ko-color-blocks">
    <div class="ko-color-block-ui_red_color">
        <div>$ui_red_color</div>
        <div>#D81D1D</div>
    </div>
    <div class="ko-color-block-ui_yellow_color">
        <div>$ui_yellow_color</div>
        <div>#FABE39</div>
    </div>
    <div class="ko-color-block-ui_green_color">
        <div>$ui_green_color</div>
        <div>#6AA220</div>
    </div>
</div>

#### Grayscale UI Text Colors

<div class="ko-color-blocks">
    <div class="ko-color-block-ui_text_dark_color">
        <div>$ui_text_dark_color</div>
        <div>#333333</div>
    </div>
    <div class="ko-color-block-ui_text_medium_color">
        <div>$ui_text_medium_color</div>
        <div>#666666</div>
    </div>
    <div class="ko-color-block-ui_text_light_color">
        <div>$ui_text_light_color</div>
        <div>#999999</div>
    </div>
</div>
<div class="ko-color-blocks">
    <div class="ko-color-block-ui_text_extra_light_color">
        <div>$ui_text_extra_light_color</div>
        <div>#CCCCCC</div>
    </div>
</div>

#### Base UI Colors

<div class="ko-color-blocks">
    <div class="ko-color-block-ui_black_color">
        <div>$ui_black_color</div>
        <div>#FFFFFF</div>
    </div>
    <div class="ko-color-block-ui_white_color">
        <div>$ui_white_color</div>
        <div>#000000</div>
    </div>
</div>

#### Team Colors

<div class="ko-color-blocks">
    <div class="ko-color-block-tagging_team_white_color">
        <div>$tagging_team_white_color</div>
        <div>#E9E9E9</div>
    </div>
    <div class="ko-color-block-tagging_team_gray_color">
        <div>$tagging_team_gray_color</div>
        <div>#8C9196</div>
    </div>
    <div class="ko-color-block-tagging_team_black_color">
        <div>$tagging_team_black_color</div>
        <div>#404040</div>
    </div>
</div>

<div class="ko-color-blocks">
    <div class="ko-color-block-tagging_team_yellow_color">
        <div>$tagging_team_yellow_color</div>
        <div>#FFDD30</div>
    </div>
    <div class="ko-color-block-tagging_team_orange_color">
        <div>$tagging_team_orange_color</div>
        <div>#ED9827</div>
    </div>
    <div class="ko-color-block-tagging_team_red_color">
        <div>$tagging_team_red_color</div>
        <div>#DF4953</div>
    </div>
</div>

<div class="ko-color-blocks">
    <div class="ko-color-block-tagging_team_green_color">
        <div>$tagging_team_green_color</div>
        <div>#42BE39</div>
    </div>
    <div class="ko-color-block-tagging_team_blue_color">
        <div>$tagging_team_blue_color</div>
        <div>#3985BE</div>
    </div>
    <div class="ko-color-block-tagging_team_purple_color">
        <div>$tagging_team_purple_color</div>
        <div>#9F62B1</div>
    </div>
</div>

### Font Sizes

These should really be used by the `font-size()` mixin to maintain consistent typography. The possible values are (from smallest to largest): **small**, **p**, and **5** to **1** (or **h5** to **h1** if that's your jam).

### Z-index

Try to stick to the variables defined here for z-index values, e.g., `z-index: $z-1` instead of arbitrary numbers. The possible values are: **z-1** (100) to **z-7** (700).

### Transition Lengths

Again, try to stick to these instead of arbitrary values. The possible values are: **t-quick** (0.2s), **t-med** (0.3s), and **t-slow** (0.5s).

### Common Breakpoints

Put breakpoints that you end up referencing a lot here and give it a prefix of `b-`, e.g., `b-showdesktop`. These are usually referenced by the `respond()` mixin.

*/
/*doc
---
title: Mixins
name: 02-mixins
category: Elements
---

### Bourbon & Neat

First off, be aware that we're using [Bourbon](http://bourbon.io/docs/) and [Neat](http://neat.bourbon.io/docs/) in Kickoff. Read up on their documentation to familiarize yourself with the mixins we get with those libraries.

### Respond()

This is our main responsive design mixin. It's built in mobile-first fashion and uses `em`s as the unit, and here's the usage:


```
.widget {

    // Default mobile-first styles here

    @include respond(20) {
        // Styles for screens from 20ems on up
    }

    @include respond(55) {
        // Styles for screens from 55rems on up
    }

    @include respond($b-showdesktop) {
        // Styles for screens from this variable on up
    }

}
```

There's an optional `ie` argument that you can pass in if you also want these styles to apply to IE8 since that browser doesn't like media queries.


```
.widget {

    // Default mobile-first styles here

    @include respond(20, ie) {
        // Styles for screens from 20ems on up and IE8
    }

}
```

Finally, the mixin applies ALL the styles to any page with a class of `desktop-only` on the `<body>`. This is to support older non-responsive and non-mobile-first pages.

### Font sizes

We've got a mixin for maintaining consistent font-sizes on a nice typographic scale. Any time you need to change the font size of an element, please use this mixin.
The possible values are (from smallest to largest): **small**, **p**, **5**, **4**, **3**, **2**, and **1**.

```
.widget {
    @include font-size(small) // .75rem
}

.widget {
    @include font-size(p) // default 1rem font size
}

.widget {
    @include font-size(5) // h5 size: 1.125rem
}

.widget {
    @include font-size(4) // h4 size: 1.3125rem
}

// etc

```

### Trade-heading()

A quick mixin for giving you that bold condensed trade heading that we all love. Pass in 'caps' to uppercase it, e.g., `trade-heading(caps)`

### Text-overflow()

For when you just don't have enough room. Hides overflow, doesn't wrap white-space and cuts off text with an ellipsis.

### CSS Spinner

There's a default `css-spinner` class that you can throw on any `<div>` but you'll likely want to customize it a bit.

```html_example
<div class="css-spinner"></div>
```

Here's how the mixin works if you want to put it on your own element and customize the colors.

```
.widget {

    // Arguments:
    // 1. border-width
    // 2. main color
    // 3. background color
    // 4. total width/height
    // 5. margin (optional. defaults to 0.)

    @include css-spinner(.5rem, $orange, $medium-gray, 4rem, 1rem);

}
```

*/
@-webkit-keyframes spinner-rotation {
  from {
    -webkit-transform: rotate(0deg); }
  to {
    -webkit-transform: rotate(359deg); } }

@-moz-keyframes spinner-rotation {
  from {
    -moz-transform: rotate(0deg); }
  to {
    -moz-transform: rotate(359deg); } }

@keyframes spinner-rotation {
  from {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg); }
  to {
    -webkit-transform: rotate(359deg);
    -moz-transform: rotate(359deg);
    -ms-transform: rotate(359deg);
    -o-transform: rotate(359deg);
    transform: rotate(359deg); } }

.css-spinner {
  -webkit-animation: spinner-rotation 0.75s infinite linear;
  -moz-animation: spinner-rotation 0.75s infinite linear;
  animation: spinner-rotation 0.75s infinite linear;
  clear: both;
  height: 4rem;
  width: 4rem;
  margin: 1rem auto 1rem auto;
  position: relative;
  border-top: 0.5rem solid #FF6600;
  border-left: 0.5rem solid #E8E8E8;
  border-bottom: 0.5rem solid #E8E8E8;
  border-right: 0.5rem solid #E8E8E8;
  border-radius: 100%;
  display: block; }
  .no-cssanimations .css-spinner {
    display: none; }

.css-spinner-message {
  margin-bottom: 1.5rem;
  padding: 0 1.5rem;
  text-align: center;
  font-size: 1rem;
  font-style: italic;
  color: #8A8A8A; }
  .no-cssanimations .css-spinner-message {
    font-weight: bold;
    padding: 1.5rem;
    color: #FF6600;
    font-size: 20px; }

/* stylelint-disable */
/* stylelint-enable */
/* stylelint-disable */
/* stylelint-enable */
/* stylelint-disable */
/* stylelint-enable */
/* stylelint-disable */
/* stylelint-enable */
/* stylelint-disable */
/* stylelint-enable */
/* stylelint-disable */
/* stylelint-enable */
/* stylelint-disable */
/* stylelint-enable */
/* stylelint-disable */
/* stylelint-enable */
/* stylelint-disable */
/* stylelint-enable */
/* stylelint-disable */
/* stylelint-enable */
/* stylelint-disable */
/* stylelint-enable */
/* stylelint-disable */
/* stylelint-enable */
/* stylelint-disable */
/* stylelint-enable */
/* stylelint-disable */
/* stylelint-enable */
/* stylelint-disable */
/* stylelint-enable */
/* stylelint-disable */
/* stylelint-enable */
/* stylelint-disable */
/* stylelint-enable */
/* stylelint-disable */
/* stylelint-enable */
/* stylelint-disable */
/* stylelint-enable */
/* stylelint-disable */
/* stylelint-enable */
/* stylelint-disable */
/* stylelint-enable */
/* stylelint-disable */
/* stylelint-enable */
/* stylelint-disable */
/* stylelint-enable */
/* stylelint-disable */
/* stylelint-enable */
/* stylelint-disable */
/* stylelint-enable */
/* stylelint-disable */
/* stylelint-enable */
.tab-panel.videos {
  border: 0;
  padding: 1rem 0; }
  @media (min-width: 30rem) {
    .tab-panel.videos {
      padding: 1rem; } }
  @media (min-width: 53rem) {
    .tab-panel.videos {
      padding: 1rem 5rem; } }

.video-controls {
  display: flex;
  align-items: center;
  color: rgba(19, 41, 63, 0.4); }
  .video-controls .controls-left {
    display: flex;
    align-items: center;
    margin: 0 1rem;
    text-align: center;
    width: 100%; }
    @media (min-width: 30rem) {
      .video-controls .controls-left {
        margin: 0; } }
    @media (min-width: 53rem) {
      .video-controls .controls-left {
        margin: 0;
        text-align: left;
        width: auto; } }
    .video-controls .controls-left .uni-form__item {
      min-width: 9rem; }
  @media (min-width: 53rem) {
    .video-controls .button-group {
      margin: 0 1rem 0 0;
      width: auto; } }
  .video-controls .button-group button {
    max-width: none;
    width: 50%; }
    @media (min-width: 53rem) {
      .video-controls .button-group button {
        width: auto; } }
  .video-controls .controls-right {
    display: none;
    margin-left: auto;
    margin-right: 0; }
    .video-controls .controls-right .create-button,
    .video-controls .controls-right .highlight-tutorials {
      display: none; }
      .video-controls .controls-right .create-button > div,
      .video-controls .controls-right .highlight-tutorials > div {
        display: inline-block; }
    @media (min-width: 53rem) {
      .video-controls .controls-right.public {
        display: block; }
        .video-controls .controls-right.public > .order-button {
          display: inline-block; } }
    @media (min-width: 53rem) {
      .video-controls .controls-right.privileged {
        display: block; }
        .video-controls .controls-right.privileged > .create-button,
        .video-controls .controls-right.privileged .highlight-tutorials {
          margin-left: 0.5rem;
          display: inline-block; } }
    @media (min-width: 74rem) {
      .video-controls .controls-right.privileged > .create-button,
      .video-controls .controls-right.privileged .highlight-tutorials {
        margin-left: 0.5rem; }
      .video-controls .controls-right.privileged > .order-button {
        display: inline-block; } }
  .video-controls label {
    display: none;
    margin-left: 2rem;
    margin-right: 0.5rem; }
    @media (min-width: 53rem) {
      .video-controls label {
        display: inline; } }
    .video-controls label.sort-label {
      margin-left: 1rem; }
  .video-controls .year-select {
    display: none;
    width: 7rem; }
    @media (min-width: 53rem) {
      .video-controls .year-select {
        display: block; } }

.videos-list {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  margin-top: 0.75rem;
  /* Safari 8 fixes */
  display: -webkit-flex;
  -webkit-flex-wrap: wrap;
  /* End Safari 8 fixes */ }
  @media (min-width: 30rem) {
    .videos-list {
      margin-right: -1rem; } }
  .videos-list .video-wrapper {
    flex: 0 1 100%;
    flex-basis: 100%;
    margin-bottom: 1rem;
    max-width: 100%; }
    @media (min-width: 30rem) {
      .videos-list .video-wrapper {
        /* Flex-basis: auto and width are set as an IE11 bug workaround */
        flex-basis: auto;
        flex: 0 1 auto;
        padding-right: 1rem;
        width: 50%; } }
    @media (min-width: 60rem) {
      .videos-list .video-wrapper {
        flex: 0 1 auto;
        width: 33.3%; } }
    .videos-list .video-wrapper:last-child {
      margin-bottom: 0; }

.video {
  background: white;
  border: 1px solid #e4e7eb;
  position: relative; }
  .video .video-info {
    margin: 0 1.25rem;
    display: flex;
    flex-direction: column; }
  .video .video-link {
    width: 100%; }
  .video .title {
    overflow: hidden;
    margin: 1rem 0 0.5rem 0;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: auto;
    word-wrap: break-word; }
  .video .draft {
    margin: -0.4rem 0 0.35rem 0; }
  .video .meta-columns {
    display: flex;
    justify-content: space-between;
    margin: 0.5rem 0; }
  .video .meta {
    display: flex;
    align-items: center;
    overflow: hidden; }
  .video .meta-text {
    display: inline-block; }
    .video .meta-text:not(:last-child) {
      margin-right: 0.5rem; }
  .video .meta-block {
    display: flex;
    align-items: center;
    height: 1rem; }
    .video .meta-block:not(:last-child) {
      margin-right: 0.5rem; }
    .video .meta-block .meta-icon {
      display: inline-block;
      height: 1rem;
      width: 1rem;
      border-radius: 50%;
      margin-right: 0.25rem; }
      .video .meta-block .meta-icon.pin-icon {
        background-color: #78a100; }
        .video .meta-block .meta-icon.pin-icon svg {
          height: 0.5rem;
          width: 0.5rem;
          margin: 0.25rem;
          fill: white; }
      .video .meta-block .meta-icon.fire-icon {
        background-color: #ffd8a5; }
        .video .meta-block .meta-icon.fire-icon svg {
          height: 0.75rem;
          width: 0.75rem;
          margin: 0.125rem; }
  .video .video-actions {
    display: flex;
    border-top: 1px solid #e4e7eb; }
    .video .video-actions .uni-tooltip__container {
      flex: 1;
      text-align: center; }
    .video .video-actions button {
      flex: 1;
      text-align: center;
      font-weight: 400;
      font-size: 12px; }
  .video .edit-video {
    display: none; }
    @media (min-width: 30rem) {
      .video .edit-video {
        display: block; } }
  @media (min-width: 30rem) {
    .video:hover .thumbnail-gradient {
      background: none; } }
  .video .thumbnail {
    background-color: #38434f;
    background-position: center;
    background-size: cover;
    padding-bottom: 56.25%;
    position: relative;
    width: 100%; }
  .video .thumbnail-gradient {
    display: none;
    left: 0;
    top: 0;
    position: absolute;
    height: 100%;
    width: 100%; }
    .video .thumbnail-gradient .rendering-text {
      display: none; }
    @media (min-width: 40rem) {
      .video .thumbnail-gradient {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center; }
        .video .thumbnail-gradient .rendering-spinner {
          display: inline-block;
          clear: both;
          width: 0.375rem;
          height: 0.375rem;
          margin: 0 auto;
          border-radius: 100%;
          animation: spinner-rotation 3rem infinite linear;
          border-top: 0.08438rem solid #ff6300;
          border-right: 0.08438rem solid rgba(124, 124, 124, 0.2);
          border-bottom: 0.08438rem solid rgba(124, 124, 124, 0.2);
          border-left: 0.08438rem solid rgba(124, 124, 124, 0.2); }
      @keyframes spinner-rotation {
        from {
          transform: rotate(0deg); }
        to {
          transform: rotate(359deg); } }
        .video .thumbnail-gradient .rendering-text {
          display: block;
          color: #e6f2ff;
          word-wrap: break-word;
          width: 90%;
          font-family: helvetica, arial, sans-serif;
          font-size: 1rem;
          overflow: hidden;
          text-align: center;
          margin-top: 0.5rem;
          margin-left: auto;
          margin-right: auto;
          position: relative; } }

@media only screen and (max-device-width: 740px) and (orientation: portrait) {
  .compact-margin {
    margin-bottom: 60px; } }

.reel-mode .uni-btn--cancel {
  color: rgba(230, 242, 255, 0.4); }
  .reel-mode .uni-btn--cancel:hover {
    color: rgba(230, 242, 255, 0.5); }
  .reel-mode .uni-btn--cancel:active {
    color: rgba(230, 242, 255, 0.3); }
