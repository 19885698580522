/* stylelint-disable */
/* stylelint-enable */
/* stylelint-disable */
/* stylelint-enable */
/* stylelint-disable */
/* stylelint-enable */
.styles__academic-card___b5PBi {
  flex: 1 1 auto;
  padding-bottom: 1.5rem; }
  .styles__academic-card___b5PBi strong {
    font-weight: bold; }

.styles__academic-card__header___3v_WE {
  display: flex; }
  .styles__academic-card__header___3v_WE h4 {
    padding-bottom: 0.25rem; }

.styles__academic-card__body___3T27E {
  display: grid;
  grid-template-columns: repeat(auto-fill, 8.5rem);
  justify-content: space-between; }

.styles__academic-card__no-access-body___fi1go {
  margin-top: 1rem;
  margin-bottom: 2rem; }

.styles__academic-card_recruiter-icon___2EoFw {
  position: relative;
  width: 18px;
  height: 18px;
  fill-rule: evenodd;
  margin-bottom: 0.125rem; }
