/* stylelint-disable */
/* stylelint-enable */
/* stylelint-disable */
/* stylelint-enable */
/* stylelint-disable */
/* stylelint-enable */
/* stylelint-disable */
/* stylelint-enable */
/* stylelint-disable */
/* stylelint-enable */
/* stylelint-disable */
/* stylelint-enable */
/* stylelint-disable */
/* stylelint-enable */
/* stylelint-disable */
/* stylelint-enable */
/* stylelint-disable */
/* stylelint-enable */
/* stylelint-disable */
/* stylelint-enable */
/* stylelint-disable */
/* stylelint-enable */
/* stylelint-disable */
/* stylelint-enable */
/* stylelint-disable */
/* stylelint-enable */
/* stylelint-disable */
/* stylelint-enable */
/* stylelint-disable */
/* stylelint-enable */
/* stylelint-disable */
/* stylelint-enable */
/* stylelint-disable */
/* stylelint-enable */
/* stylelint-disable */
/* stylelint-enable */
/* stylelint-disable */
/* stylelint-enable */
/* stylelint-disable */
/* stylelint-enable */
/* stylelint-disable */
/* stylelint-enable */
/* stylelint-disable */
/* stylelint-enable */
/* stylelint-disable */
/* stylelint-enable */
/* stylelint-disable */
/* stylelint-enable */
/* stylelint-disable */
/* stylelint-enable */
/* stylelint-disable */
/* stylelint-enable */
/*
Some fixes and overrides until we can go 100% Uniform
*/
.uni-btn,
.uni-btn-icon-only,
.uni-btn--medium,
.uni-btn--small,
.uni-btn--primary,
.uni-btn--secondary,
.uni-btn--confirm,
.uni-btn--destroy,
.uni-btn--subtle {
  font-family: helvetica, arial, sans-serif; }
  .uni-btn svg,
  .uni-btn-icon-only svg,
  .uni-btn--medium svg,
  .uni-btn--small svg,
  .uni-btn--primary svg,
  .uni-btn--secondary svg,
  .uni-btn--confirm svg,
  .uni-btn--destroy svg,
  .uni-btn--subtle svg {
    vertical-align: middle; }

.uni-btn__group--combo > button {
  transition: none;
  min-width: 7.5rem; }

.styles__contact-card___1cB5o {
  width: 20rem;
  margin: 0 0.75rem 1.5rem; }
  .styles__contact-card___1cB5o > .uni-card {
    display: flex;
    min-height: 100%; }
    .styles__contact-card___1cB5o > .uni-card > .uni-card__body {
      display: flex;
      flex-direction: column;
      justify-content: space-between; }

.styles__contact-card__header___1VcNC {
  line-height: 1.5rem; }
  .styles__contact-card__header___1VcNC .uni-subhead, .styles__contact-card__header___1VcNC .uni-text {
    display: inline; }

.styles__contact-card__row___3Ms6M {
  display: flex;
  margin-top: 0.5rem; }

.styles__contact-card__row-label___C_UhV {
  min-width: 4.5rem;
  line-height: 1.5rem; }

.styles__contact-card__email___2uSIt {
  overflow-wrap: anywhere; }

.styles__contact-card__prompt___En97p {
  margin-top: 1rem;
  border-top: 1px solid #e4e7eb;
  padding-top: 0.5rem; }
