/* stylelint-disable */
/* stylelint-enable */
/* stylelint-disable */
/* stylelint-enable */
/* stylelint-disable */
/* stylelint-enable */
/* stylelint-disable */
/* stylelint-enable */
/* stylelint-disable */
/* stylelint-enable */
/* stylelint-disable */
/* stylelint-enable */
/* stylelint-disable */
/* stylelint-enable */
/* stylelint-disable */
/* stylelint-enable */
/* stylelint-disable */
/* stylelint-enable */
/* stylelint-disable */
/* stylelint-enable */
/* stylelint-disable */
/* stylelint-enable */
/* stylelint-disable */
/* stylelint-enable */
/* stylelint-disable */
/* stylelint-enable */
/* stylelint-disable */
/* stylelint-enable */
/* stylelint-disable */
/* stylelint-enable */
/* stylelint-disable */
/* stylelint-enable */
/* stylelint-disable */
/* stylelint-enable */
/* stylelint-disable */
/* stylelint-enable */
/* stylelint-disable */
/* stylelint-enable */
/* stylelint-disable */
/* stylelint-enable */
/* stylelint-disable */
/* stylelint-enable */
/* stylelint-disable */
/* stylelint-enable */
/* stylelint-disable */
/* stylelint-enable */
/* stylelint-disable */
/* stylelint-enable */
/* stylelint-disable */
/* stylelint-enable */
/* stylelint-disable */
/* stylelint-enable */
.prof-header {
  color: rgba(19, 41, 63, 0.8);
  font-family: helvetica, arial, sans-serif;
  position: relative;
  transform: translate3d(0, 0, 0);
  width: 100%;
  z-index: 500; }
  @media (min-width: 48rem) {
    .prof-header {
      transition: height 0.05s linear; } }

.prof-banner {
  height: 100%;
  transform: translate3d(0, 0, 0); }
  .prof-banner:hover .camera-icon-banner {
    opacity: 1; }
  .prof-banner:hover .prof-header-image-shade {
    opacity: 0.75; }

.prof-header-image {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 100%;
  width: 100%;
  z-index: -1; }

.prof-header-image-shade {
  background-color: #000;
  opacity: 0;
  height: 100%;
  width: 100%;
  transition: 0.2s ease-in-out; }

.camera-icon-banner {
  width: 3.125rem;
  opacity: 0;
  position: absolute;
  transition: opacity 0.25s ease-in-out;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%); }

.prof-ribbon {
  padding-left: 50%;
  position: absolute;
  top: -23px;
  z-index: 1;
  width: 100%; }

.prof-ribbon-primary,
.prof-ribbon-secondary {
  position: absolute;
  width: 100%; }
  .prof-ribbon-primary svg,
  .prof-ribbon-secondary svg {
    transform: translateX(-187.5px);
    height: 50px;
    width: 768px; }
    @media (min-width: 23.5rem) {
      .prof-ribbon-primary svg,
      .prof-ribbon-secondary svg {
        transform: translateX(-384px); } }
  @media (min-width: 23.5rem) {
    .prof-ribbon-primary .ribbon-mob,
    .prof-ribbon-secondary .ribbon-mob {
      display: none; } }
  .prof-ribbon-primary .ribbon-tab,
  .prof-ribbon-secondary .ribbon-tab {
    display: none; }
    @media (min-width: 23.5rem) {
      .prof-ribbon-primary .ribbon-tab,
      .prof-ribbon-secondary .ribbon-tab {
        display: block; } }
    @media (min-width: 48rem) {
      .prof-ribbon-primary .ribbon-tab,
      .prof-ribbon-secondary .ribbon-tab {
        display: none; } }
  .prof-ribbon-primary .ribbon-desk,
  .prof-ribbon-secondary .ribbon-desk {
    display: none;
    height: 30px;
    width: 150%;
    transform: translateX(-50%); }
    @media (min-width: 48rem) {
      .prof-ribbon-primary .ribbon-desk,
      .prof-ribbon-secondary .ribbon-desk {
        display: block; } }

.prof-ribbon-primary {
  top: 9px;
  z-index: 200; }
  @media (min-width: 48rem) {
    .prof-ribbon-primary {
      top: 27px; } }

.prof-ribbon-secondary {
  z-index: 100;
  top: 5px; }
  @media (min-width: 48rem) {
    .prof-ribbon-secondary {
      top: 23px; } }

.prof-ribbon-texture {
  transform: translateX(-50%);
  background-position: center top;
  background-repeat: no-repeat;
  background-size: 100% auto;
  position: absolute;
  height: 57px;
  top: 12px;
  width: 375px;
  z-index: 300;
  background-image: url("https://static.hudl.com/profiles/images/textures/mob-prof-ribbon-texture.png"); }
  @media only screen and (-webkit-min-device-pixel-ratio: 1.3), only screen and (min--moz-device-pixel-ratio: 1.3), only screen and (-o-min-device-pixel-ratio: 1.3 / 1), only screen and (min-resolution: 125dpi), only screen and (min-resolution: 1.3dppx) {
    .prof-ribbon-texture {
      background-image: url("https://static.hudl.com/profiles/images/textures/mob-prof-ribbon-texture_2x.png"); } }
  @media (min-width: 23.5rem) {
    .prof-ribbon-texture {
      background-image: url("https://static.hudl.com/profiles/images/textures/tab-prof-ribbon-texture.png");
      top: 6px;
      width: 768px; } }
  @media only screen and (min-width: 23.5rem) and (-webkit-min-device-pixel-ratio: 1.3), only screen and (min-width: 23.5rem) and (min--moz-device-pixel-ratio: 1.3), only screen and (min-width: 23.5rem) and (-o-min-device-pixel-ratio: 1.3 / 1), only screen and (min-width: 23.5rem) and (min-resolution: 125dpi), only screen and (min-width: 23.5rem) and (min-resolution: 1.3dppx) {
    .prof-ribbon-texture {
      background-image: url("https://static.hudl.com/profiles/images/textures/tab-prof-ribbon-texture_2x.png"); } }
  @media (min-width: 48rem) {
    .prof-ribbon-texture {
      background-image: url("https://static.hudl.com/profiles/images/textures/desk-prof-ribbon-texture.png");
      background-repeat: repeat-x;
      background-size: auto;
      top: 25px;
      height: 37px;
      width: 100%; } }
  @media only screen and (min-width: 48rem) and (-webkit-min-device-pixel-ratio: 1.3), only screen and (min-width: 48rem) and (min--moz-device-pixel-ratio: 1.3), only screen and (min-width: 48rem) and (-o-min-device-pixel-ratio: 1.3 / 1), only screen and (min-width: 48rem) and (min-resolution: 125dpi), only screen and (min-width: 48rem) and (min-resolution: 1.3dppx) {
    .prof-ribbon-texture {
      background-image: url("https://static.hudl.com/profiles/images/textures/desk-prof-ribbon-texture_2x.png"); } }

.prof-image-wrap {
  opacity: 1;
  position: absolute;
  top: -56px;
  margin-left: -3.875rem;
  transition: opacity 0.05s ease-in;
  z-index: 700;
  border-radius: 50%;
  left: 50%;
  height: 124px;
  width: 124px;
  background-color: white; }
  .prof-image-wrap.fade-out {
    opacity: 0; }
  @media (max-width: 68.6875rem) {
    .prof-image-wrap .uni-avatar {
      width: 124px;
      height: 124px;
      min-width: initial;
      min-height: initial; }
      .prof-image-wrap .uni-avatar .uni-avatar__img {
        height: initial; } }
  @media (min-width: 68.75rem) {
    .prof-image-wrap {
      top: -24px;
      margin-left: 0;
      background: white;
      left: 1.25rem;
      height: inherit;
      width: inherit; } }

.prof-image:hover .shadow {
  box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.75); }

.prof-image:hover .camera-icon-profile {
  opacity: 1; }

.shadow {
  transition: box-shadow 0.25s ease-in-out;
  position: absolute;
  left: 0px;
  top: 0px;
  right: 0px;
  bottom: 0px;
  border-radius: 50%; }

.prof-image-inner {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  border-style: solid;
  border-width: 8px;
  height: 100%;
  width: 100%;
  text-align: center; }

.camera-icon-profile {
  width: 30%;
  height: 30%;
  position: absolute;
  opacity: 0;
  transition: opacity 0.25s ease-in-out;
  top: 36%;
  left: 36%; }

.prof-ident-bg {
  background-image: linear-gradient(rgba(255, 255, 255, 0.85) 0%, white 20%);
  border-bottom: 1px solid #edf0f2;
  position: relative;
  transform: translate3d(0, 0, 0);
  z-index: 500; }

.prof-ident {
  margin: 0 auto;
  max-width: 1200px;
  position: relative;
  padding: 5rem 1.25rem 1.25rem 1.25rem;
  text-align: center; }
  @media (min-width: 68.75rem) {
    .prof-ident {
      padding: 3rem 1.25rem 1.25rem 14.5rem;
      text-align: left; } }

.prof-ident-title {
  display: flex; }

.prof-ident-name {
  flex: 1; }

.prof-ident-bio {
  color: rgba(19, 41, 63, 0.8);
  font-size: 14px;
  line-height: 20px;
  margin: 1rem auto;
  max-width: 37rem;
  word-wrap: break-word; }
  @media (min-width: 68.75rem) {
    .prof-ident-bio {
      display: none; } }

.prof-ident-bio-items {
  margin: 0 auto 1.25rem auto; }
  @media (min-width: 68.75rem) {
    .prof-ident-bio-items {
      margin: 0.25rem 0 0 0; }
      .prof-ident-bio-items svg {
        display: none; } }

.prof-ident-bio-item {
  display: inline-block;
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap; }
  .prof-ident-bio-item div {
    display: inline-block; }
  @media (min-width: 23.5rem) {
    .prof-ident-bio-item:not(:last-child) {
      margin-right: 1.5rem; } }

@media (min-width: 68.75rem) {
  .prof-ident-meta {
    margin: 0.5rem 0 0 0; } }

.prof-ident-meta.mobile {
  margin: 0.5rem; }

.prof-ident-meta-item {
  display: inline-block; }
  .prof-ident-meta-item.mobile {
    padding: 0.25rem; }
  .prof-ident-meta-item.desktop {
    padding: 0 1rem; }
  @media (min-width: 68.75rem) {
    .prof-ident-meta-item {
      padding: 0 1rem 0 0; } }
  .prof-ident-meta-item strong {
    font-weight: 700 !important; }

.prof-nav-wrapper {
  background: white;
  top: 0;
  z-index: 400; }
  .prof-nav-wrapper.sticky {
    position: sticky; }

.prof-header-buttons {
  display: flex; }
  .prof-header-buttons > *:not(:first-child) {
    margin-left: 0.5rem; }
  .prof-header-buttons.buttons-mobile {
    margin: auto 1.25rem auto;
    flex-wrap: wrap;
    justify-content: center; }
    @media (min-width: 68.75rem) {
      .prof-header-buttons.buttons-mobile {
        display: none; } }
    .prof-header-buttons.buttons-mobile > * {
      margin-bottom: 0.5rem; }
  .prof-header-buttons.buttons-desktop {
    display: none;
    margin: 0 0 0 1rem; }
    @media (min-width: 68.75rem) {
      .prof-header-buttons.buttons-desktop {
        display: inline-block; } }
