/* stylelint-disable */
/* stylelint-enable */
/* stylelint-disable */
/* stylelint-enable */
/* stylelint-disable */
/* stylelint-enable */
/* stylelint-disable */
/* stylelint-enable */
/* stylelint-disable */
/* stylelint-enable */
/* stylelint-disable */
/* stylelint-enable */
/* stylelint-disable */
/* stylelint-enable */
/* stylelint-disable */
/* stylelint-enable */
/* stylelint-disable */
/* stylelint-enable */
/* stylelint-disable */
/* stylelint-enable */
/* stylelint-disable */
/* stylelint-enable */
/* stylelint-disable */
/* stylelint-enable */
/* stylelint-disable */
/* stylelint-enable */
/* stylelint-disable */
/* stylelint-enable */
/* stylelint-disable */
/* stylelint-enable */
/* stylelint-disable */
/* stylelint-enable */
/* stylelint-disable */
/* stylelint-enable */
/* stylelint-disable */
/* stylelint-enable */
/* stylelint-disable */
/* stylelint-enable */
/* stylelint-disable */
/* stylelint-enable */
/* stylelint-disable */
/* stylelint-enable */
/* stylelint-disable */
/* stylelint-enable */
/* stylelint-disable */
/* stylelint-enable */
/* stylelint-disable */
/* stylelint-enable */
/* stylelint-disable */
/* stylelint-enable */
/* stylelint-disable */
/* stylelint-enable */
.profile-nav {
  display: block;
  height: 2.5rem;
  width: 100%;
  position: relative;
  background-color: white;
  padding: 0 1.25rem; }
  @media (min-width: 30rem) {
    .profile-nav {
      padding: 0 4rem;
      height: 3.125rem; } }
  @media (min-width: 50rem) {
    .profile-nav {
      padding: 0 8rem; } }
  @media (min-width: 62.5rem) {
    .profile-nav {
      padding: 0 10.3125rem; } }
  .profile-nav .nav-members {
    position: absolute;
    bottom: 0;
    min-width: 20rem; }
    .profile-nav .nav-members li {
      font-size: 0.875rem;
      height: 100%;
      display: inline-block;
      color: #232a31;
      opacity: 0.5;
      line-height: 2.375rem; }
      @media (min-width: 30rem) {
        .profile-nav .nav-members li {
          font-size: 1rem;
          line-height: 3rem; } }
      .profile-nav .nav-members li:not(:last-child) {
        margin-right: 1rem; }
        @media (min-width: 65rem) {
          .profile-nav .nav-members li:not(:last-child) {
            margin-right: 2.5rem; } }
      .profile-nav .nav-members li.active-item, .profile-nav .nav-members li:hover {
        cursor: pointer;
        border-bottom: 3px solid #232a31;
        opacity: 1; }

@media (min-width: 68.75rem) {
  .prof-nav-wrapper {
    position: sticky;
    top: 0rem; } }

.prof-subnav {
  background: white;
  box-shadow: 0 2px 8px 0 rgba(35, 42, 49, 0.05);
  min-height: 3.125rem;
  position: relative;
  top: 0;
  transform: translate3d(0, 0, 0);
  transition: opacity 0.1s ease-in;
  z-index: 400;
  width: 100%; }
  @media (min-width: 68.75rem) {
    .prof-subnav.sticky .prof-subnav-right,
    .prof-subnav.sticky .prof-subnav-left {
      flex: 1;
      opacity: 1;
      display: flex;
      overflow: hidden;
      visibility: visible;
      width: 330px; } }
  .prof-subnav .prof-subnav-inner {
    display: flex;
    height: 100%;
    margin: 0 auto;
    max-width: 1200px;
    width: 100%; }
  .prof-subnav .prof-subnav-middle {
    display: flex;
    flex-wrap: wrap;
    flex: 1;
    height: 100%;
    justify-content: center; }
    @media (min-width: 68.75rem) {
      .prof-subnav .prof-subnav-middle {
        padding: 0 0.5rem; } }
  .prof-subnav .prof-subnav-left,
  .prof-subnav .prof-subnav-right {
    align-items: center;
    display: none;
    height: 100%;
    opacity: 0;
    transition: opacity 0.05s ease-in;
    visibility: hidden;
    width: 0; }
  .prof-subnav .prof-subnav-left {
    padding-left: 1.25rem; }
  .prof-subnav .prof-subnav-right {
    justify-content: flex-end;
    padding-right: 1.25rem; }
  .prof-subnav .prof-subnav-avatar {
    align-items: center;
    border-radius: 50%;
    color: #e6f2ff;
    display: flex;
    flex: 1 0 30px;
    font-weight: bold;
    height: 30px;
    justify-content: center;
    margin-right: 6px;
    max-width: 30px;
    width: 30px; }
  .prof-subnav .prof-subnav-name {
    font-size: 18px;
    font-weight: bold;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap; }

.prof-subnav-item {
  padding: 1rem 0.75rem;
  margin: 0;
  border-bottom: 2px solid;
  border-bottom-color: transparent;
  color: rgba(19, 41, 63, 0.65);
  cursor: pointer;
  display: flex;
  flex-basis: 94px;
  justify-content: center;
  text-align: center;
  text-decoration: none;
  transition: color 0.25s ease, border-color 0.15s ease; }
  .prof-subnav-item:hover {
    color: rgba(19, 41, 63, 0.8);
    border-bottom-color: rgba(19, 41, 63, 0.4); }
  .prof-subnav-item.active {
    color: rgba(19, 41, 63, 0.8);
    border-bottom-color: rgba(19, 41, 63, 0.8); }
  @media (min-width: 68.75rem) {
    .prof-subnav-item {
      padding: 1rem 2rem; } }

.prof-subnav-item__icon {
  margin-left: 0.5rem;
  text-align: left; }
