/* stylelint-disable */
/* stylelint-enable */
/* stylelint-disable */
/* stylelint-enable */
/* stylelint-disable */
/* stylelint-enable */
.styles__expandible-list___3dOlT {
  margin-top: 0.5rem; }

.styles__expandable-list__header___CFWdd button {
  padding-left: 0 !important; }

.styles__expandable-list__header___CFWdd svg {
  margin-left: 0.5rem; }

.styles__expandable-list__items___1rkcJ {
  margin-left: 0.125rem; }
