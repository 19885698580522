/* stylelint-disable */
/* stylelint-enable */
/* stylelint-disable */
/* stylelint-enable */
/* stylelint-disable */
/* stylelint-enable */
.delete-highlight-dialog .react-modal {
  height: 18rem; }

.delete-highlight-dialog .react-modal-content {
  padding: 1rem; }

.delete-highlight-dialog .bold {
  font-weight: bold; }

.delete-highlight-dialog p {
  margin-bottom: 1rem; }

.delete-highlight-dialog .delete-highlight-btn {
  background-color: #e81c00;
  color: white; }
  .delete-highlight-dialog .delete-highlight-btn:hover, .delete-highlight-dialog .delete-highlight-btn:focus {
    background-color: #e81c00;
    color: white; }
  .delete-highlight-dialog .delete-highlight-btn:active, .delete-highlight-dialog .delete-highlight-btn:disabled, .delete-highlight-dialog .delete-highlight-btn:disabled:hover {
    background-color: #cf1900;
    background-image: none; }
  .delete-highlight-dialog .delete-highlight-btn div {
    display: inline-block; }
  .delete-highlight-dialog .delete-highlight-btn svg, .delete-highlight-dialog .delete-highlight-btn path {
    fill: white; }
