.team-selection__team-selection__organization___3ImOx {
  display: block; }

.team-selection__team-selection__team-name___1xfBB {
  display: block; }

.team-selection__team-selection__team___2lDCV {
  display: flex; }

.team-selection__team-selection___2WiyX {
  margin-right: 1rem; }
  .team-selection__team-selection___2WiyX .uni-select__items {
    max-height: 12rem !important; }
  .team-selection__team-selection___2WiyX .uni-select {
    min-width: 16rem; }
