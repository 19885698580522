/* stylelint-disable */
/* stylelint-enable */
/* stylelint-disable */
/* stylelint-enable */
/* stylelint-disable */
/* stylelint-enable */
.button-group {
  display: flex;
  background-color: #e4e7eb;
  border-radius: 4px;
  font-size: .875rem;
  max-height: 32px;
  width: auto;
  margin-right: auto; }
  .button-group .button-group-toggle {
    padding: 0 1rem;
    color: rgba(19, 41, 63, 0.65);
    border: 2px solid transparent;
    transition: color 0.15s ease;
    height: 32px;
    display: flex;
    align-items: center; }
    .button-group .button-group-toggle:hover {
      color: rgba(19, 41, 63, 0.8);
      cursor: pointer; }
  .button-group .button-group-toggle--selected {
    background-color: white;
    color: #13293f;
    border: 2px solid #e4e7eb;
    border-radius: 4px; }
