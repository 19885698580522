.RestrictedAccess__games-tab__restricted-access___2hsqb {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 2rem;
  text-align: center;
  max-width: 100%; }

.RestrictedAccess__games-tab__restricted-access-teams-list___2vbhF {
  width: 28rem;
  max-width: 100%;
  margin-top: 1.5rem;
  text-align: left; }
