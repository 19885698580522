/* stylelint-disable */
/* stylelint-enable */
/* stylelint-disable */
/* stylelint-enable */
/* stylelint-disable */
/* stylelint-enable */
/* stylelint-disable */
/* stylelint-enable */
/* stylelint-disable */
/* stylelint-enable */
/* stylelint-disable */
/* stylelint-enable */
/* stylelint-disable */
/* stylelint-enable */
/* stylelint-disable */
/* stylelint-enable */
/* stylelint-disable */
/* stylelint-enable */
/* stylelint-disable */
/* stylelint-enable */
/* stylelint-disable */
/* stylelint-enable */
/* stylelint-disable */
/* stylelint-enable */
/* stylelint-disable */
/* stylelint-enable */
/* stylelint-disable */
/* stylelint-enable */
/* stylelint-disable */
/* stylelint-enable */
/* stylelint-disable */
/* stylelint-enable */
/* stylelint-disable */
/* stylelint-enable */
/* stylelint-disable */
/* stylelint-enable */
/* stylelint-disable */
/* stylelint-enable */
/* stylelint-disable */
/* stylelint-enable */
/* stylelint-disable */
/* stylelint-enable */
/* stylelint-disable */
/* stylelint-enable */
/* stylelint-disable */
/* stylelint-enable */
/* stylelint-disable */
/* stylelint-enable */
/* stylelint-disable */
/* stylelint-enable */
/* stylelint-disable */
/* stylelint-enable */
.sharing-dialog .copy-field {
  transition: all 0.2s linear;
  margin: 1rem 2rem -1rem 2rem;
  height: 4rem;
  opacity: 1;
  position: relative; }
  .sharing-dialog .copy-field.hidden {
    display: none; }

.sharing-dialog .react-modal {
  overflow-y: hidden;
  transition: all 0.3s, height 0.2s linear;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column; }
  .sharing-dialog .react-modal .react-modal-content {
    height: 100%;
    width: 100%;
    margin-bottom: 0;
    position: relative;
    top: 0;
    bottom: 0; }
  @media (min-width: 32rem) {
    .sharing-dialog .react-modal {
      height: auto;
      width: 32rem; } }

.sharing-dialog .smooth-modal-content {
  overflow-y: hidden; }

.sharing-dialog .sharing-content {
  flex-direction: column;
  justify-content: space-between;
  display: flex;
  padding: 1rem; }
  @media (min-width: 36rem) {
    .sharing-dialog .sharing-content {
      flex-direction: row;
      padding: 1rem 1.5rem; } }
  .sharing-dialog .sharing-content #share-modal__url-share-button {
    display: inline-flex;
    justify-content: center; }

.sharing-dialog .sharing-content--public {
  padding-top: 1.5rem;
  margin: 0 1.5rem; }

.sharing-dialog .sharing-content--public-subtitle {
  padding-top: 0.5rem; }

.sharing-dialog .sharing-content--recruit {
  padding: 1.5rem 0;
  margin: 0 1.5rem;
  border-top: 1px solid rgba(167, 174, 181, 0.6); }
