/*doc
---
title: Variables
name: 01-variables
category: Elements
---

### Colors

#### Dark UI Colors

<div class="ko-color-blocks">
    <div class="ko-color-block-ui_dark_color">
        <div>$dui_dark_color</div>
        <div>#232A31</div>
    </div>
    <div class="ko-color-block-ui_dark_medium_color">
        <div>$ui_dark_medium_color</div>
        <div>#38434F</div>
    </div>
    <div class="ko-color-block-ui_dark_light_color">
        <div>$ui_dark_light_color</div>
        <div>#4E5D6C</div>
    </div>
</div>

#### Gray UI Colors

<div class="ko-color-blocks">
    <div class="ko-color-block-ui_gray_color">
        <div>$ui_gray_color</div>
        <div>#8A8A8A</div>
    </div>
    <div class="ko-color-block-ui_gray_medium_color">
        <div>$ui_gray_medium_color</div>
        <div>#E8E8E8</div>
    </div>
    <div class="ko-color-block-ui_gray_light_color">
        <div>$ui_gray_light_color</div>
        <div>#F5F5F5</div>
    </div>
</div>

#### Accent UI Colors

<div class="ko-color-blocks">
    <div class="ko-color-block-ui_blue_color">
        <div>$ui_blue_color</div>
        <div>#00AEEF</div>
    </div>
    <div class="ko-color-block-ui_orange_color">
        <div>$ui_orange_color</div>
        <div>#FF6600</div>
    </div>
</div>

#### Alert UI Colors

<div class="ko-color-blocks">
    <div class="ko-color-block-ui_red_color">
        <div>$ui_red_color</div>
        <div>#D81D1D</div>
    </div>
    <div class="ko-color-block-ui_yellow_color">
        <div>$ui_yellow_color</div>
        <div>#FABE39</div>
    </div>
    <div class="ko-color-block-ui_green_color">
        <div>$ui_green_color</div>
        <div>#6AA220</div>
    </div>
</div>

#### Grayscale UI Text Colors

<div class="ko-color-blocks">
    <div class="ko-color-block-ui_text_dark_color">
        <div>$ui_text_dark_color</div>
        <div>#333333</div>
    </div>
    <div class="ko-color-block-ui_text_medium_color">
        <div>$ui_text_medium_color</div>
        <div>#666666</div>
    </div>
    <div class="ko-color-block-ui_text_light_color">
        <div>$ui_text_light_color</div>
        <div>#999999</div>
    </div>
</div>
<div class="ko-color-blocks">
    <div class="ko-color-block-ui_text_extra_light_color">
        <div>$ui_text_extra_light_color</div>
        <div>#CCCCCC</div>
    </div>
</div>

#### Base UI Colors

<div class="ko-color-blocks">
    <div class="ko-color-block-ui_black_color">
        <div>$ui_black_color</div>
        <div>#FFFFFF</div>
    </div>
    <div class="ko-color-block-ui_white_color">
        <div>$ui_white_color</div>
        <div>#000000</div>
    </div>
</div>

#### Team Colors

<div class="ko-color-blocks">
    <div class="ko-color-block-tagging_team_white_color">
        <div>$tagging_team_white_color</div>
        <div>#E9E9E9</div>
    </div>
    <div class="ko-color-block-tagging_team_gray_color">
        <div>$tagging_team_gray_color</div>
        <div>#8C9196</div>
    </div>
    <div class="ko-color-block-tagging_team_black_color">
        <div>$tagging_team_black_color</div>
        <div>#404040</div>
    </div>
</div>

<div class="ko-color-blocks">
    <div class="ko-color-block-tagging_team_yellow_color">
        <div>$tagging_team_yellow_color</div>
        <div>#FFDD30</div>
    </div>
    <div class="ko-color-block-tagging_team_orange_color">
        <div>$tagging_team_orange_color</div>
        <div>#ED9827</div>
    </div>
    <div class="ko-color-block-tagging_team_red_color">
        <div>$tagging_team_red_color</div>
        <div>#DF4953</div>
    </div>
</div>

<div class="ko-color-blocks">
    <div class="ko-color-block-tagging_team_green_color">
        <div>$tagging_team_green_color</div>
        <div>#42BE39</div>
    </div>
    <div class="ko-color-block-tagging_team_blue_color">
        <div>$tagging_team_blue_color</div>
        <div>#3985BE</div>
    </div>
    <div class="ko-color-block-tagging_team_purple_color">
        <div>$tagging_team_purple_color</div>
        <div>#9F62B1</div>
    </div>
</div>

### Font Sizes

These should really be used by the `font-size()` mixin to maintain consistent typography. The possible values are (from smallest to largest): **small**, **p**, and **5** to **1** (or **h5** to **h1** if that's your jam).

### Z-index

Try to stick to the variables defined here for z-index values, e.g., `z-index: $z-1` instead of arbitrary numbers. The possible values are: **z-1** (100) to **z-7** (700).

### Transition Lengths

Again, try to stick to these instead of arbitrary values. The possible values are: **t-quick** (0.2s), **t-med** (0.3s), and **t-slow** (0.5s).

### Common Breakpoints

Put breakpoints that you end up referencing a lot here and give it a prefix of `b-`, e.g., `b-showdesktop`. These are usually referenced by the `respond()` mixin.

*/
/*doc
---
title: Mixins
name: 02-mixins
category: Elements
---

### Bourbon & Neat

First off, be aware that we're using [Bourbon](http://bourbon.io/docs/) and [Neat](http://neat.bourbon.io/docs/) in Kickoff. Read up on their documentation to familiarize yourself with the mixins we get with those libraries.

### Respond()

This is our main responsive design mixin. It's built in mobile-first fashion and uses `em`s as the unit, and here's the usage:


```
.widget {

    // Default mobile-first styles here

    @include respond(20) {
        // Styles for screens from 20ems on up
    }

    @include respond(55) {
        // Styles for screens from 55rems on up
    }

    @include respond($b-showdesktop) {
        // Styles for screens from this variable on up
    }

}
```

There's an optional `ie` argument that you can pass in if you also want these styles to apply to IE8 since that browser doesn't like media queries.


```
.widget {

    // Default mobile-first styles here

    @include respond(20, ie) {
        // Styles for screens from 20ems on up and IE8
    }

}
```

Finally, the mixin applies ALL the styles to any page with a class of `desktop-only` on the `<body>`. This is to support older non-responsive and non-mobile-first pages.

### Font sizes

We've got a mixin for maintaining consistent font-sizes on a nice typographic scale. Any time you need to change the font size of an element, please use this mixin.
The possible values are (from smallest to largest): **small**, **p**, **5**, **4**, **3**, **2**, and **1**.

```
.widget {
    @include font-size(small) // .75rem
}

.widget {
    @include font-size(p) // default 1rem font size
}

.widget {
    @include font-size(5) // h5 size: 1.125rem
}

.widget {
    @include font-size(4) // h4 size: 1.3125rem
}

// etc

```

### Trade-heading()

A quick mixin for giving you that bold condensed trade heading that we all love. Pass in 'caps' to uppercase it, e.g., `trade-heading(caps)`

### Text-overflow()

For when you just don't have enough room. Hides overflow, doesn't wrap white-space and cuts off text with an ellipsis.

### CSS Spinner

There's a default `css-spinner` class that you can throw on any `<div>` but you'll likely want to customize it a bit.

```html_example
<div class="css-spinner"></div>
```

Here's how the mixin works if you want to put it on your own element and customize the colors.

```
.widget {

    // Arguments:
    // 1. border-width
    // 2. main color
    // 3. background color
    // 4. total width/height
    // 5. margin (optional. defaults to 0.)

    @include css-spinner(.5rem, $orange, $medium-gray, 4rem, 1rem);

}
```

*/
@-webkit-keyframes spinner-rotation {
  from {
    -webkit-transform: rotate(0deg); }
  to {
    -webkit-transform: rotate(359deg); } }

@-moz-keyframes spinner-rotation {
  from {
    -moz-transform: rotate(0deg); }
  to {
    -moz-transform: rotate(359deg); } }

@keyframes spinner-rotation {
  from {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg); }
  to {
    -webkit-transform: rotate(359deg);
    -moz-transform: rotate(359deg);
    -ms-transform: rotate(359deg);
    -o-transform: rotate(359deg);
    transform: rotate(359deg); } }

.css-spinner {
  -webkit-animation: spinner-rotation 0.75s infinite linear;
  -moz-animation: spinner-rotation 0.75s infinite linear;
  animation: spinner-rotation 0.75s infinite linear;
  clear: both;
  height: 4rem;
  width: 4rem;
  margin: 1rem auto 1rem auto;
  position: relative;
  border-top: 0.5rem solid #FF6600;
  border-left: 0.5rem solid #E8E8E8;
  border-bottom: 0.5rem solid #E8E8E8;
  border-right: 0.5rem solid #E8E8E8;
  border-radius: 100%;
  display: block; }
  .no-cssanimations .css-spinner {
    display: none; }

.css-spinner-message {
  margin-bottom: 1.5rem;
  padding: 0 1.5rem;
  text-align: center;
  font-size: 1rem;
  font-style: italic;
  color: #8A8A8A; }
  .no-cssanimations .css-spinner-message {
    font-weight: bold;
    padding: 1.5rem;
    color: #FF6600;
    font-size: 20px; }

.hide-wrap-and-nav .super-wrap,
.hide-wrap-and-nav .main-header {
  display: none; }
  @media only screen and (min-width: 37.5em) {
    .hide-wrap-and-nav .super-wrap,
    .hide-wrap-and-nav .main-header {
      display: block; } }
  html.no-rgba .hide-wrap-and-nav .super-wrap, html.no-rgba
  .hide-wrap-and-nav .main-header {
    display: block; }
  .desktop-only .hide-wrap-and-nav .super-wrap, .desktop-only
  .hide-wrap-and-nav .main-header {
    display: block; }

.hide-wrap-and-nav.no-rgba .super-wrap,
.hide-wrap-and-nav.no-rgba .main-header {
  display: block; }

@media only screen and (min-width: 37.5em) {
  .smooth-modal-display body {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    overflow: hidden; } }

html.no-rgba .smooth-modal-display body {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  overflow: hidden; }

.desktop-only .smooth-modal-display body {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  overflow: hidden; }

.smooth-modal-display.no-rgba body {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  overflow: hidden; }

.smooth-modal-overlay, .react-modal-overlay {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  display: none;
  z-index: 200;
  opacity: 0;
  -webkit-transition: 0.3s;
  -moz-transition: 0.3s;
  transition: 0.3s; }
  @media only screen and (min-width: 37.5em) {
    .smooth-modal-overlay, .react-modal-overlay {
      background-color: rgba(35, 42, 49, 0.8); } }
  html.no-rgba .smooth-modal-overlay, html.no-rgba .react-modal-overlay {
    background-color: rgba(35, 42, 49, 0.8); }
  .desktop-only .smooth-modal-overlay, .desktop-only .react-modal-overlay {
    background-color: rgba(35, 42, 49, 0.8); }
  .no-rgba .smooth-modal-overlay, .no-rgba .react-modal-overlay {
    background-color: #8A8A8A; }
  .smooth-modal-display .smooth-modal-overlay, .smooth-modal-display .react-modal-overlay {
    display: block; }
  .smooth-modal-slide .smooth-modal-overlay, .smooth-modal-slide .react-modal-overlay {
    opacity: 1; }

.smooth-modal {
  position: relative;
  width: 100%;
  height: 100%;
  z-index: 400;
  color: #232A31;
  background-color: #FFFFFF;
  overflow: hidden;
  -webkit-transition: 0.3s;
  -moz-transition: 0.3s;
  transition: 0.3s;
  -webkit-transform: scale(0.5);
  -moz-transform: scale(0.5);
  -ms-transform: scale(0.5);
  -o-transform: scale(0.5);
  transform: scale(0.5); }
  @media only screen and (min-width: 37.5em) {
    .smooth-modal {
      position: absolute;
      width: 420px;
      left: 50%;
      margin-left: -210px;
      height: 80%;
      top: 10%;
      background-color: #FFFFFF;
      border-radius: 2px; } }
  html.no-rgba .smooth-modal {
    position: absolute;
    width: 420px;
    left: 50%;
    margin-left: -210px;
    height: 80%;
    top: 10%;
    background-color: #FFFFFF;
    border-radius: 2px; }
  .desktop-only .smooth-modal {
    position: absolute;
    width: 420px;
    left: 50%;
    margin-left: -210px;
    height: 80%;
    top: 10%;
    background-color: #FFFFFF;
    border-radius: 2px; }
  .smooth-modal-slide .smooth-modal {
    -webkit-transform: scale(1);
    -moz-transform: scale(1);
    -ms-transform: scale(1);
    -o-transform: scale(1);
    transform: scale(1); }
  .smooth-modal header {
    background-color: #38434F;
    width: 100%;
    z-index: 100;
    height: 3rem;
    display: -webkit-inline-box;
    display: -moz-inline-box;
    display: inline-box;
    display: -webkit-inline-flex;
    display: -moz-inline-flex;
    display: -ms-inline-flexbox;
    display: inline-flex;
    -webkit-box-align: center;
    -moz-box-align: center;
    box-align: center;
    -webkit-align-items: center;
    -moz-align-items: center;
    -ms-align-items: center;
    -o-align-items: center;
    align-items: center;
    -ms-flex-align: center;
    -webkit-box-orient: horizontal;
    -moz-box-orient: horizontal;
    box-orient: horizontal;
    -webkit-box-direction: normal;
    -moz-box-direction: normal;
    box-direction: normal;
    -webkit-flex-direction: row;
    -moz-flex-direction: row;
    flex-direction: row;
    -ms-flex-direction: row;
    -webkit-box-pack: justify;
    -moz-box-pack: justify;
    box-pack: justify;
    -webkit-justify-content: space-between;
    -moz-justify-content: space-between;
    -ms-justify-content: space-between;
    -o-justify-content: space-between;
    justify-content: space-between;
    -ms-flex-pack: justify; }
    .smooth-modal header::after {
      clear: both;
      content: "";
      display: table; }
    @media only screen and (min-width: 6em) {
      .smooth-modal header {
        border-radius: 1px 1px 0px 0px; } }
    html.no-rgba .smooth-modal header {
      border-radius: 1px 1px 0px 0px; }
    .desktop-only .smooth-modal header {
      border-radius: 1px 1px 0px 0px; }
    .smooth-modal header .smooth-modal-back,
    .smooth-modal header .smooth-modal-close {
      margin-top: 0.25rem;
      padding: 1rem;
      cursor: pointer; }
      .smooth-modal header .smooth-modal-back > svg,
      .smooth-modal header .smooth-modal-close > svg {
        width: 1rem;
        height: 1rem;
        fill: #FFFFFF; }
        .smooth-modal header .smooth-modal-back > svg > *,
        .smooth-modal header .smooth-modal-close > svg > * {
          fill: #FFFFFF; }
    .smooth-modal header h2 {
      font-size: 16px;
      color: #FFFFFF;
      text-align: center;
      width: 100%;
      z-index: 500;
      font-family: trade;
      font-weight: bold;
      text-transform: uppercase; }
  .smooth-modal .smooth-modal-content, .smooth-modal .smooth-modal-content-static, .smooth-modal .react-modal-overlay .react-modal-content, .react-modal-overlay .smooth-modal .react-modal-content {
    overflow-y: auto;
    overflow-x: hidden;
    -webkit-overflow-scrolling: touch;
    position: absolute;
    width: 100%;
    bottom: 0;
    top: 3rem;
    background-color: #FFFFFF; }
    .smooth-modal .smooth-modal-content.has-footer, .smooth-modal .has-footer.smooth-modal-content-static, .smooth-modal .react-modal-overlay .has-footer.react-modal-content, .react-modal-overlay .smooth-modal .has-footer.react-modal-content {
      bottom: 5rem; }
    .smooth-modal .smooth-modal-content.no-header, .smooth-modal .no-header.smooth-modal-content-static, .smooth-modal .react-modal-overlay .no-header.react-modal-content, .react-modal-overlay .smooth-modal .no-header.react-modal-content {
      top: 0; }
    .smooth-modal .smooth-modal-content h4, .smooth-modal .smooth-modal-content-static h4, .smooth-modal .react-modal-overlay .react-modal-content h4, .react-modal-overlay .smooth-modal .react-modal-content h4 {
      background-color: gainsboro;
      border-top: 1px solid #d9d9d9;
      border-bottom: 1px solid #d9d9d9;
      color: #232A31;
      text-align: center;
      padding: 0.25rem 0.5rem;
      font-family: trade;
      font-weight: bold;
      font-size: 0.75rem; }
    .smooth-modal .smooth-modal-content textarea, .smooth-modal .smooth-modal-content-static textarea, .smooth-modal .react-modal-overlay .react-modal-content textarea, .react-modal-overlay .smooth-modal .react-modal-content textarea {
      padding: 1rem;
      width: 100%;
      min-height: 100px;
      font-family: trade, sans-serif;
      border: 1px solid #FF6600;
      font-size: 1rem; }
  .smooth-modal .smooth-modal-content-static {
    position: static; }
  .smooth-modal section::after {
    clear: both;
    content: "";
    display: table; }
  .smooth-modal footer {
    position: absolute;
    bottom: 0;
    width: 100%;
    z-index: 100;
    border-top: 1px solid #cfcfcf;
    background-color: #E8E8E8;
    padding: 1rem;
    height: 5rem;
    display: -webkit-box;
    display: -moz-box;
    display: box;
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -moz-box-align: center;
    box-align: center;
    -webkit-align-items: center;
    -moz-align-items: center;
    -ms-align-items: center;
    -o-align-items: center;
    align-items: center;
    -ms-flex-align: center;
    -webkit-box-orient: horizontal;
    -moz-box-orient: horizontal;
    box-orient: horizontal;
    -webkit-box-direction: normal;
    -moz-box-direction: normal;
    box-direction: normal;
    -webkit-flex-direction: row;
    -moz-flex-direction: row;
    flex-direction: row;
    -ms-flex-direction: row;
    -webkit-box-pack: end;
    -moz-box-pack: end;
    box-pack: end;
    -webkit-justify-content: flex-end;
    -moz-justify-content: flex-end;
    -ms-justify-content: flex-end;
    -o-justify-content: flex-end;
    justify-content: flex-end;
    -ms-flex-pack: end; }
    .smooth-modal footer::after {
      clear: both;
      content: "";
      display: table; }
    .smooth-modal footer button {
      margin-right: 0.5rem; }
      .smooth-modal footer button:last-child {
        margin-right: 0rem; }

.smooth-modal-season-switcher {
  background-color: #232A31;
  width: 100%;
  height: 3rem;
  color: #FFFFFF;
  text-align: center;
  z-index: 100;
  display: -webkit-inline-box;
  display: -moz-inline-box;
  display: inline-box;
  display: -webkit-inline-flex;
  display: -moz-inline-flex;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
  -moz-box-align: center;
  box-align: center;
  -webkit-align-items: center;
  -moz-align-items: center;
  -ms-align-items: center;
  -o-align-items: center;
  align-items: center;
  -ms-flex-align: center;
  -webkit-box-orient: horizontal;
  -moz-box-orient: horizontal;
  box-orient: horizontal;
  -webkit-box-direction: normal;
  -moz-box-direction: normal;
  box-direction: normal;
  -webkit-flex-direction: row;
  -moz-flex-direction: row;
  flex-direction: row;
  -ms-flex-direction: row;
  -webkit-box-pack: justify;
  -moz-box-pack: justify;
  box-pack: justify;
  -webkit-justify-content: space-between;
  -moz-justify-content: space-between;
  -ms-justify-content: space-between;
  -o-justify-content: space-between;
  justify-content: space-between;
  -ms-flex-pack: justify; }
  .smooth-modal-season-switcher .season-next,
  .smooth-modal-season-switcher .season-prev {
    padding: 1rem;
    cursor: pointer; }
    .smooth-modal-season-switcher .season-next svg,
    .smooth-modal-season-switcher .season-prev svg {
      width: 1rem;
      height: 1rem;
      fill: #FFFFFF; }
      .smooth-modal-season-switcher .season-next svg > *,
      .smooth-modal-season-switcher .season-prev svg > * {
        fill: #FFFFFF; }
    .smooth-modal-season-switcher .season-next.disabled svg > *,
    .smooth-modal-season-switcher .season-prev.disabled svg > * {
      fill: gray; }

.smooth-modal-selection-list li {
  color: #232A31;
  padding: 1.25rem 1rem;
  width: 100%;
  border-bottom: 1px solid #E8E8E8;
  cursor: pointer;
  display: -webkit-inline-box;
  display: -moz-inline-box;
  display: inline-box;
  display: -webkit-inline-flex;
  display: -moz-inline-flex;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
  -moz-box-align: center;
  box-align: center;
  -webkit-align-items: center;
  -moz-align-items: center;
  -ms-align-items: center;
  -o-align-items: center;
  align-items: center;
  -ms-flex-align: center;
  -webkit-box-orient: horizontal;
  -moz-box-orient: horizontal;
  box-orient: horizontal;
  -webkit-box-direction: normal;
  -moz-box-direction: normal;
  box-direction: normal;
  -webkit-flex-direction: row;
  -moz-flex-direction: row;
  flex-direction: row;
  -ms-flex-direction: row;
  -webkit-box-pack: justify;
  -moz-box-pack: justify;
  box-pack: justify;
  -webkit-justify-content: space-between;
  -moz-justify-content: space-between;
  -ms-justify-content: space-between;
  -o-justify-content: space-between;
  justify-content: space-between;
  -ms-flex-pack: justify; }
  .smooth-modal-selection-list li::after {
    clear: both;
    content: "";
    display: table; }
  .smooth-modal-selection-list li .sm-selection-list-item {
    width: 100%; }
  .smooth-modal-selection-list li .sm-selection-list-item-icon {
    width: 0.75rem;
    height: 0.75rem; }
    .smooth-modal-selection-list li .sm-selection-list-item-icon svg {
      fill: #232A31; }

.smooth-modal-checkbox-list li {
  color: #232A31;
  padding: 0 1rem 1rem 1rem;
  width: 100%;
  cursor: pointer;
  line-height: 1.5rem;
  display: -webkit-inline-box;
  display: -moz-inline-box;
  display: inline-box;
  display: -webkit-inline-flex;
  display: -moz-inline-flex;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
  -moz-box-align: center;
  box-align: center;
  -webkit-align-items: center;
  -moz-align-items: center;
  -ms-align-items: center;
  -o-align-items: center;
  align-items: center;
  -ms-flex-align: center;
  -webkit-box-orient: horizontal;
  -moz-box-orient: horizontal;
  box-orient: horizontal;
  -webkit-box-direction: normal;
  -moz-box-direction: normal;
  box-direction: normal;
  -webkit-flex-direction: row;
  -moz-flex-direction: row;
  flex-direction: row;
  -ms-flex-direction: row;
  -webkit-box-pack: justify;
  -moz-box-pack: justify;
  box-pack: justify;
  -webkit-justify-content: space-between;
  -moz-justify-content: space-between;
  -ms-justify-content: space-between;
  -o-justify-content: space-between;
  justify-content: space-between;
  -ms-flex-pack: justify; }
  .smooth-modal-checkbox-list li::after {
    clear: both;
    content: "";
    display: table; }
  .smooth-modal-checkbox-list li:first-child {
    padding-top: 1rem; }

/* stylelint-disable */
/* stylelint-enable */
/* stylelint-disable */
/* stylelint-enable */
/* stylelint-disable */
/* stylelint-enable */
/* stylelint-disable */
/* stylelint-enable */
/* stylelint-disable */
/* stylelint-enable */
/* stylelint-disable */
/* stylelint-enable */
/* stylelint-disable */
/* stylelint-enable */
/* stylelint-disable */
/* stylelint-enable */
/* stylelint-disable */
/* stylelint-enable */
/* stylelint-disable */
/* stylelint-enable */
/* stylelint-disable */
/* stylelint-enable */
/* stylelint-disable */
/* stylelint-enable */
/* stylelint-disable */
/* stylelint-enable */
/* stylelint-disable */
/* stylelint-enable */
/* stylelint-disable */
/* stylelint-enable */
/* stylelint-disable */
/* stylelint-enable */
/* stylelint-disable */
/* stylelint-enable */
/* stylelint-disable */
/* stylelint-enable */
/* stylelint-disable */
/* stylelint-enable */
/* stylelint-disable */
/* stylelint-enable */
/* stylelint-disable */
/* stylelint-enable */
/* stylelint-disable */
/* stylelint-enable */
/* stylelint-disable */
/* stylelint-enable */
/* stylelint-disable */
/* stylelint-enable */
/* stylelint-disable */
/* stylelint-enable */
/* stylelint-disable */
/* stylelint-enable */
.react-modal {
  /* Default state is full scale */
  transform: scale(1);
  transition: none;
  margin: 0;
  left: 0;
  top: 0;
  position: relative;
  max-height: 100vh;
  background-color: white;
  border-radius: 8px; }
  .react-modal header {
    display: flex;
    justify-content: space-between;
    background-color: white;
    padding: 1.5rem 1.5rem 1rem 1.5rem;
    border-radius: 8px;
    border-bottom: 1px solid #edf0f2; }
  .react-modal .react-modal-content {
    overflow-y: auto;
    overflow-x: hidden;
    -webkit-overflow-scrolling: touch;
    position: absolute;
    width: 100%;
    bottom: 0;
    top: 4.5rem; }

/* Overlay must default to visible */
.react-modal-overlay {
  display: flex;
  align-items: center;
  justify-content: center;
  transition: none;
  opacity: 1;
  z-index: 900;
  background-color: rgba(10, 31, 51, 0.6); }
  body > .react-modal-overlay {
    display: none;
    opacity: 0; }

/* ReactCSSTransitionGroup styles */
.react-modal-enter.react-modal-overlay {
  opacity: 0.01; }

.react-modal-enter .react-modal {
  transform: scale(0.5);
  -webkit-backface-visibility: hidden; }

.react-modal-enter.react-modal-enter-active.react-modal-overlay {
  transition: 0.3s;
  opacity: 1; }

.react-modal-enter.react-modal-enter-active .react-modal {
  transition: 0.3s;
  transform: scale(1);
  -webkit-backface-visibility: hidden; }

.react-modal-leave.react-modal-overlay {
  opacity: 1; }

.react-modal-leave .react-modal {
  transform: scale(1); }

.react-modal-leave.react-modal-leave-active.react-modal-overlay {
  transition: 0.3s;
  opacity: 0; }

.react-modal-leave.react-modal-leave-active .react-modal {
  transition: 0.3s;
  transform: scale(0.5);
  -webkit-backface-visibility: hidden; }
