.styles__athleticism-card___1xSFZ {
  flex: 1 1 auto;
  padding-bottom: 1.5rem; }

.styles__athleticism-card__header___2k650 {
  padding-bottom: 1rem; }

.styles__athleticism-card__body___QZePG {
  display: grid;
  grid-template-columns: repeat(auto-fill, 8.5rem);
  justify-content: space-between; }
