/* stylelint-disable */
/* stylelint-enable */
/* stylelint-disable */
/* stylelint-enable */
/* stylelint-disable */
/* stylelint-enable */
.styles__no-recruiter-access___2Umbr {
  text-align: center;
  margin: auto;
  width: 70%;
  justify-content: space-between; }

.styles__no-recruiter-access__contact___1GC3D {
  margin-top: 1rem; }
