/* stylelint-disable */
/* stylelint-enable */
/* stylelint-disable */
/* stylelint-enable */
/* stylelint-disable */
/* stylelint-enable */
.styles__no-recruiter-access___okxXw {
  text-align: center;
  margin: auto;
  width: 70%;
  justify-content: space-between; }
