.styles__essentials-card___AkzHM {
  flex: 1 1 auto;
  padding-bottom: 1.5rem; }

.styles__essential-item___1VOaL {
  padding-top: 0.5rem; }
  .styles__essential-item___1VOaL:first-child {
    padding-top: 0; }

.styles__essential-item__label___259Vl {
  display: inline; }

.styles__essential-item__value___2T_jc {
  display: inline;
  line-height: 1.5rem; }
