/* stylelint-disable */
/* stylelint-enable */
/* stylelint-disable */
/* stylelint-enable */
/* stylelint-disable */
/* stylelint-enable */
/* stylelint-disable */
/* stylelint-enable */
/* stylelint-disable */
/* stylelint-enable */
/* stylelint-disable */
/* stylelint-enable */
/* stylelint-disable */
/* stylelint-enable */
/* stylelint-disable */
/* stylelint-enable */
/* stylelint-disable */
/* stylelint-enable */
/* stylelint-disable */
/* stylelint-enable */
/* stylelint-disable */
/* stylelint-enable */
/* stylelint-disable */
/* stylelint-enable */
/* stylelint-disable */
/* stylelint-enable */
/* stylelint-disable */
/* stylelint-enable */
/* stylelint-disable */
/* stylelint-enable */
/* stylelint-disable */
/* stylelint-enable */
/* stylelint-disable */
/* stylelint-enable */
/* stylelint-disable */
/* stylelint-enable */
/* stylelint-disable */
/* stylelint-enable */
/* stylelint-disable */
/* stylelint-enable */
/* stylelint-disable */
/* stylelint-enable */
/* stylelint-disable */
/* stylelint-enable */
/* stylelint-disable */
/* stylelint-enable */
/* stylelint-disable */
/* stylelint-enable */
/* stylelint-disable */
/* stylelint-enable */
/* stylelint-disable */
/* stylelint-enable */
.followers-dialog .react-modal {
  width: 100%;
  height: 100%; }
  .followers-dialog .react-modal .react-modal-content {
    margin-bottom: 0; }
  @media (min-width: 32rem) {
    .followers-dialog .react-modal {
      height: 31.3125rem;
      width: 32.625rem; } }

.followers-dialog .followers-content {
  display: flex;
  flex-direction: column;
  padding: 1rem;
  width: 100%; }
  .followers-dialog .followers-content .team-followers-spinner {
    margin: 0 auto; }
  .followers-dialog .followers-content .team-followers-error-requesting {
    color: #e81c00;
    text-align: center;
    width: 100%;
    margin-top: 1rem; }
