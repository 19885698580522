/* stylelint-disable */
/* stylelint-enable */
/* stylelint-disable */
/* stylelint-enable */
/* stylelint-disable */
/* stylelint-enable */
.uni-modal__content {
  margin: 0 -1.5rem; }

.style__ImageStyle___2BSey {
  display: inline-block;
  content: url("https://static.hudl.com/images/profiles/Delete_Highlighted_Clips.jpg");
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25)); }

.style__ContentWrapper___1TTPA {
  margin-top: 0.125rem; }

.style__TextContainer___8FFam {
  padding-top: 0.5rem; }

.style__Content___20_ex {
  padding-top: 1rem;
  margin: 0 1.5rem; }
