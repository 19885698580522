/* stylelint-disable */
/* stylelint-enable */
/* stylelint-disable */
/* stylelint-enable */
/* stylelint-disable */
/* stylelint-enable */
/* stylelint-disable */
/* stylelint-enable */
/* stylelint-disable */
/* stylelint-enable */
/* stylelint-disable */
/* stylelint-enable */
/* stylelint-disable */
/* stylelint-enable */
/* stylelint-disable */
/* stylelint-enable */
/* stylelint-disable */
/* stylelint-enable */
/* stylelint-disable */
/* stylelint-enable */
/* stylelint-disable */
/* stylelint-enable */
/* stylelint-disable */
/* stylelint-enable */
/* stylelint-disable */
/* stylelint-enable */
/* stylelint-disable */
/* stylelint-enable */
/* stylelint-disable */
/* stylelint-enable */
/* stylelint-disable */
/* stylelint-enable */
/* stylelint-disable */
/* stylelint-enable */
/* stylelint-disable */
/* stylelint-enable */
/* stylelint-disable */
/* stylelint-enable */
/* stylelint-disable */
/* stylelint-enable */
/* stylelint-disable */
/* stylelint-enable */
/* stylelint-disable */
/* stylelint-enable */
/* stylelint-disable */
/* stylelint-enable */
/* stylelint-disable */
/* stylelint-enable */
/* stylelint-disable */
/* stylelint-enable */
/* stylelint-disable */
/* stylelint-enable */
.team-follower-row {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0.5rem 0;
  border-bottom: 1px solid #e4e7eb; }
  .team-follower-row .team-follower-info {
    order: 2;
    display: flex;
    flex-direction: column; }
    .team-follower-row .team-follower-info-top, .team-follower-row .team-follower-info-middle {
      max-width: 15rem; }
      @media (min-width: 32rem) {
        .team-follower-row .team-follower-info-top, .team-follower-row .team-follower-info-middle {
          max-width: 23rem; } }
    .team-follower-row .team-follower-info-top {
      display: flex;
      align-items: center; }
    .team-follower-row .team-follower-info-middle {
      display: flex;
      flex-direction: row; }
      .team-follower-row .team-follower-info-middle .team-follower-school-name, .team-follower-row .team-follower-info-middle .team-follower-middot {
        display: none; }
        @media (min-width: 32rem) {
          .team-follower-row .team-follower-info-middle .team-follower-school-name, .team-follower-row .team-follower-info-middle .team-follower-middot {
            display: inline; } }
    .team-follower-row .team-follower-info strong {
      font-weight: 700; }
  .team-follower-row .follow-button {
    order: 3;
    margin-left: auto; }
