/* stylelint-disable */
/* stylelint-enable */
/* stylelint-disable */
/* stylelint-enable */
/* stylelint-disable */
/* stylelint-enable */
/* stylelint-disable */
/* stylelint-enable */
/* stylelint-disable */
/* stylelint-enable */
/* stylelint-disable */
/* stylelint-enable */
/* stylelint-disable */
/* stylelint-enable */
/* stylelint-disable */
/* stylelint-enable */
/* stylelint-disable */
/* stylelint-enable */
/* stylelint-disable */
/* stylelint-enable */
/* stylelint-disable */
/* stylelint-enable */
/* stylelint-disable */
/* stylelint-enable */
/* stylelint-disable */
/* stylelint-enable */
/* stylelint-disable */
/* stylelint-enable */
/* stylelint-disable */
/* stylelint-enable */
/* stylelint-disable */
/* stylelint-enable */
/* stylelint-disable */
/* stylelint-enable */
/* stylelint-disable */
/* stylelint-enable */
/* stylelint-disable */
/* stylelint-enable */
/* stylelint-disable */
/* stylelint-enable */
/* stylelint-disable */
/* stylelint-enable */
/* stylelint-disable */
/* stylelint-enable */
/* stylelint-disable */
/* stylelint-enable */
.styles__contact-tab__panel___3bqTD {
  max-width: 66.5rem;
  margin: 0 auto;
  padding: 0 1rem; }
  .styles__contact-tab__panel___3bqTD strong {
    font-weight: bold; }

.styles__contact-tab__content___227DX {
  width: 100%;
  display: flex;
  justify-content: center; }

.styles__contact-tab__contacts___1kl11 {
  display: flex;
  flex-wrap: wrap;
  justify-content: center; }
  @media (min-width: 68.75rem) {
    .styles__contact-tab__contacts___1kl11 {
      justify-content: left; } }

.styles__contact-tab__notice___QkQyc {
  margin-top: 3rem; }

.styles__contact-tab__incomplete___8gR20 {
  text-align: center;
  margin: auto;
  width: 70%; }
