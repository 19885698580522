/* stylelint-disable */
/* stylelint-enable */
/* stylelint-disable */
/* stylelint-enable */
/* stylelint-disable */
/* stylelint-enable */
/* stylelint-disable */
/* stylelint-enable */
/* stylelint-disable */
/* stylelint-enable */
/* stylelint-disable */
/* stylelint-enable */
/* stylelint-disable */
/* stylelint-enable */
/* stylelint-disable */
/* stylelint-enable */
/* stylelint-disable */
/* stylelint-enable */
/* stylelint-disable */
/* stylelint-enable */
/* stylelint-disable */
/* stylelint-enable */
/* stylelint-disable */
/* stylelint-enable */
/* stylelint-disable */
/* stylelint-enable */
/* stylelint-disable */
/* stylelint-enable */
/* stylelint-disable */
/* stylelint-enable */
/* stylelint-disable */
/* stylelint-enable */
/* stylelint-disable */
/* stylelint-enable */
/* stylelint-disable */
/* stylelint-enable */
/* stylelint-disable */
/* stylelint-enable */
/* stylelint-disable */
/* stylelint-enable */
/* stylelint-disable */
/* stylelint-enable */
/* stylelint-disable */
/* stylelint-enable */
/* stylelint-disable */
/* stylelint-enable */
/* stylelint-disable */
/* stylelint-enable */
/* stylelint-disable */
/* stylelint-enable */
/* stylelint-disable */
/* stylelint-enable */
.image-cropper-modal .react-modal {
  width: 100%;
  height: 100%; }
  .image-cropper-modal .react-modal .react-modal-content {
    margin-bottom: 0;
    display: flex;
    flex-direction: column; }
  @media (min-width: 32rem) {
    .image-cropper-modal .react-modal {
      height: 39.75rem;
      max-height: 100%;
      width: 43.75rem; } }

.image-cropper-modal .image-cropper-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: auto;
  padding: 0;
  flex: 1 1 auto;
  overflow: auto;
  position: relative; }
  .image-cropper-modal .image-cropper-content canvas:hover {
    cursor: move; }
  .image-cropper-modal .image-cropper-content .image-drag-cta {
    padding: 0.25rem;
    width: 10rem;
    opacity: 0.6;
    background-color: #313b45;
    border: solid 1px white;
    color: white;
    font-weight: bold;
    text-align: center;
    position: absolute;
    bottom: 4.75rem;
    left: 50%;
    margin-left: -5rem;
    user-select: none;
    pointer-events: none; }
  .image-cropper-modal .image-cropper-content .slider {
    position: absolute;
    bottom: 1rem;
    left: 25%;
    width: 50%; }

.image-cropper-modal .image-cropper-error {
  display: flex;
  align-items: center;
  height: 3.375rem;
  background-color: #e81c00;
  padding: 0 1.25rem; }
  .image-cropper-modal .image-cropper-error p {
    color: white;
    font-size: 1rem;
    line-height: 1.5;
    font-family: helvetica, arial, sans-serif; }

.image-cropper-modal .image-cropper-footer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: white;
  padding: 1rem 1.5rem 1.5rem 1.5rem;
  border-radius: 8px; }
  .image-cropper-modal .image-cropper-footer button:first-child {
    margin-right: 0.5rem; }
  .image-cropper-modal .image-cropper-footer .image-cropper-requirements-text {
    display: none; }
    @media (min-width: 29.25rem) {
      .image-cropper-modal .image-cropper-footer .image-cropper-requirements-text {
        display: block; }
        .image-cropper-modal .image-cropper-footer .image-cropper-requirements-text p {
          font-family: helvetica, arial, sans-serif;
          font-size: 0.75rem;
          color: #818a8a; } }
