/* Disabled for inital commit, remove as file touched */
/* stylelint-disable */
.games-card {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex: 0 0 auto;
  margin-right: 0.5rem;
  overflow: hidden;
  position: relative;
  cursor: pointer;
  transition: box-shadow 0.25s;
  border-bottom: 1px solid #edf0f2;
  text-decoration: none;
  width: calc(33.333% - 1rem); }

.margin-mask {
  background: #f9fafb;
  width: 1.875rem;
  margin-left: -0.5rem;
  margin-right: -0.5rem; }

.pre-data--1 {
  animation-delay: 0s; }

.pre-data--2 {
  animation-delay: -1s; }

.pre-data--3 {
  animation-delay: -2s; }

.games-card.pre-data {
  border: none; }
  .games-card.pre-data .thumbnail {
    background: none;
    margin-bottom: 0;
    cursor: pointer;
    padding-bottom: 56.25%; }
  .games-card.pre-data .thumbnail-margin-mask {
    height: 0.75rem;
    background: #f9fafb; }
  .games-card.pre-data .title-split-container {
    height: 1rem;
    position: relative; }
    .games-card.pre-data .title-split-container .title-split-mask {
      position: absolute;
      background: #f9fafb;
      height: 100%;
      width: 3rem;
      right: 3rem; }
  .games-card.pre-data .title-margin-mask {
    height: 0.75rem;
    background: #f9fafb; }
  .games-card.pre-data .metadata-split-container {
    height: 0.8rem;
    position: relative; }
    .games-card.pre-data .metadata-split-container .metadata-split-mask {
      position: absolute;
      background: #f9fafb;
      height: 100%;
      width: 4rem;
      right: 0; }
  .games-card.pre-data .metadata-margin-mask {
    height: 0.5rem;
    background: #f9fafb; }
  .games-card.pre-data .tags-split-container {
    height: 0.8rem;
    position: relative; }
    .games-card.pre-data .tags-split-container .tags-split-mask {
      position: absolute;
      background: #f9fafb;
      height: 100%;
      width: 9rem;
      right: 0; }

@keyframes placeHolderShimmer {
  0% {
    background-position: -1277px 0; }
  100% {
    background-position: 1277px 0; } }

.pre-data {
  animation-duration: 2.75s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-name: placeHolderShimmer;
  animation-timing-function: linear; }

.pre-data,
.pre-data.item {
  background: #f9fafb;
  background: linear-gradient(to right, #f2f5f7 8%, #d3d8de 18%, #f2f5f7 33%);
  background-size: 1277px 540px; }

.games-group {
  margin-bottom: 1rem;
  flex: 1 1 100%;
  display: flex;
  flex-flow: row wrap;
  width: 100%; }
